import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React from "react";

function SignOutView() {
    const navigate = useNavigate();
    return (
        <div>
            <Header showLogout={false}> </Header>
            <div className='buttons-holder'>
                You have been signed out of the Covera Provider Portal.<br />
                <p style={{ marginTop: "6px" }}>
                    <a href="/" className="" onClick={() => navigate('/')} data-testid='signin'> Click here </a>to sign in again.
                </p>
            </div>

            <Footer />
        </div>
    )
}
export default SignOutView;