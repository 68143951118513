import Footer from "../Footer";
import Table from "./Table";
import PropTypes from 'prop-types';
import React from 'react';
import DisclaimerTop from "../disclaimer_components/DisclaimerTop";
import DisclaimerBottom from "../disclaimer_components/DisclaimerBottom";
function ReferralList(props) {

    return (

        <div>
            <div id="content" className="card">
                <div className="flex flex-wrap gap-2 mb-10">
                    <h1 className="m-0 fw-400 h1-b">Authorizations</h1>
                    <span className="fw-400">The following patients have approved authorizations that are part of Covera Health’s radiology quality programs. Please refer to the list of designated radiologists for interpretation.</span>
                </div>
                {(props.state === 'ACTIVE' || props.state === 'PROMPT') && <Table
                    apiConfig={props.apiConfig}></Table>}
                <div className="row coe">
                    <span>
                        <p>
                            <DisclaimerBottom/>
                            <DisclaimerTop/>
                        </p>
                    </span>
                </div>
            </div>   
            <div id="app-footer">
                <Footer setSelectedTab={props.setSelectedTab} user={props.user}/>
            </div>
            
        </div>
    );
}
ReferralList.propTypes = {
    apiConfig: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
};
export default ReferralList;