import { API_ENDPOINTS } from "../../../utils/UrlUtils";
import { getUserActionLabel } from "../../../utils/Utils";
import { invokeGetApi, invokePostApi, invokePostApiWithoutAuthHeader } from "../../../utils/api";

export const ManageUsersService = {
 
  //Function to add/update
  async addOrUpdateUserData(userData, action) {
    let url =
      action === getUserActionLabel().ADD ? API_ENDPOINTS.ADD_USER : API_ENDPOINTS.UPDATE_USER;
    let requestData = {
      given_name: userData["given_name"],
      family_name: userData["family_name"],
      email: userData["email"],
      status: userData["enabled"],
      admin: userData["admin"] 
    };
    return await invokePostApi(url, requestData);
  },

  async resendInvitation(userData) {
    let url = API_ENDPOINTS.RESEND_INVITATION;
    let requestData = {
      given_name: userData["given_name"],
      family_name: userData["family_name"],
      email: userData["email"],
      status: userData["enabled"],
      admin: userData["admin"]
    };
    return await invokePostApi(url, requestData);
  },

  async resetPassword(email) {
    let url = API_ENDPOINTS.RESET_PASSWORD;
    let requestData = {
      email: email,
    };
    return await invokePostApi(url, requestData);
  },

  async getUserAgreement(email) {
    let url = API_ENDPOINTS.USER_AGREEMENT;
    let requestData = {
      email: email,
    };
    return await invokePostApi(url, requestData);
  },

  async setUserAgreementAcceptance(email) {
    let url = API_ENDPOINTS.USER_AGREEMENT_ACCEPT;
    let requestData = {
      email: email,
    };
    return await invokePostApi(url, requestData);
  },

  async confirmForgotPassword(data) {
    let requestData = {
      code: data["code"],
      password: data["password"],
      confirm_password: data["confirm_password"],
      password_reset_code: data["password_reset_code"], //userstatus
    };

    return await invokePostApiWithoutAuthHeader(API_ENDPOINTS.CHANGE_PASSWORD, requestData);
  },

  async get_masked_email(request_code){    
    let requestData = {
      password_reset_code: request_code,
    };

    return await invokePostApiWithoutAuthHeader(API_ENDPOINTS.GET_MASKED_MAIL, requestData);
  },

  getUsers() {    
    return invokeGetApi(API_ENDPOINTS.GET_USERS);
  },
};

