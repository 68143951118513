import React from 'react';

function TermsAndConditions(){
    return (
        <div className="limit-width">
            <p>Covera Health, Inc. and its affiliated companies (<strong>“Covera” “we”</strong> or <strong>“us”</strong>) thanks you for using our Services. These Terms of Use (<strong>“Terms”</strong>) govern your access to and use of the websites (<strong>“Sites”</strong>) and other online services, in each case that we own and/or operate and that link to these Terms (collectively, the<strong> “Services”</strong>). The Services include all software and Content (as defined below) provided through or as part of the Services.
            </p>
            <p>Please read these Terms carefully before you start to use the Services, as they are a legal agreement between Covera and you. <strong> Terms contain important provisions that limit our liability to you. By accessing or using the Services, or by clicking to accept or agree to these Terms when this option is made available to you, you accept and agree to be bound by these Terms without limitation or qualification.
            </strong> If you do not want to agree to these Terms, please do not access or use the Services.</p>
            <h2>IMPORTANT DISCLAIMERS</h2>
            <p>1.<strong> Covera does not provide medical care services.</strong> Covera provides technologies to help health care providers improve quality outcomes.</p>
            <p>2.<strong> Dial 911 in the case of an emergency.</strong> Covera is not a health care provider and cannot provide assistance in the case of an emergency.</p>
            <h2>PRIVACY AND SECURITY</h2>
            <p>We take steps to protect your privacy when you use the Services, and we maintain appropriate administrative and technical controls to safeguard the security and privacy of your information. However, no security measures or communications over the Internet can be 100% secure, and we cannot guarantee the security of your information.To learn about our information collection, use, and privacy and security practices associated with the Services, please review our <a className="hyper-link" href="https://www.coverahealth.com/privacy-policy.html" target="_blank">Privacy Policy.</a></p>
            <h2>ACCOUNT REGISTRATION</h2>
            <p>Although certain parts of the Services are accessible without creating an account, you may be required to create an account to access and use other parts of the Services. If you create an account, you agree to provide information that is current, accurate, and complete, and to maintain and update that information. If you do not keep such information current, or we have reasonable grounds to suspect as much, we have the right to suspend or terminate your account and your use of the Services.</p>
            <p>You agree to keep your username and password confidential and to exit from your account at the end of each session. You are responsible for all activities that occur under your account and for maintaining the confidentiality of your password. You also agree to immediately notify us of any unauthorized use of your username, password or any other breach of security that you become aware of involving or relating to the Services by emailing us at <a className="hyper-link" href="mailto:legaloperations@coverahealth.com">legaloperations@coverahealth.com</a>. We may take any and all actions we deem necessary or reasonable to maintain the security of the Services.</p>
            <p>You are responsible for changing your password promptly if you think it has been compromised. You may not transfer or share your password with anyone or create more than one account. You may not use anyone else's account at any time. We disclaim liability for any and all losses and damages arising from your failure to comply with this section.</p>
            <h2>CHILDREN AND MINORS</h2>
            <p>The Services are not intended for or directed to children under 18 years old. We do not intend to solicit information or to market any products or services to children through this Site.</p>
            <h2>CHANGES TO THESE TERMS</h2>
            <p>We reserve the right to change or replace these Terms at any time. Please check back from time to time to ensure that you are aware of any changes or updates to these Terms. We will indicate the Terms’ effective date at the top of this page. If we make material changes that would impact your use of the Services, we will endeavor to notify you of the changes, such as by posting a notice directly on the Services, by sending an email notification (if you have provided your email address to us), or by any other reasonable method. Your continued use of the Services after changes have been posted indicates your acceptance of any changed Terms.</p>
            <h2>RESERVATION OF RIGHTS</h2>
            <p>We reserve the right at any time and for any reason, with or without notice, and without liability to you or any other user, to: (1) modify, suspend or terminate the operation or any feature of the Services; (2) restrict, limit, suspend or terminate your and/or any other user’s access to the Services; (3) monitor any user’s use of the Services to verify compliance with these Terms and/or any applicable law; (4) investigate any suspected or alleged misuse of the Services and cooperate with law enforcement and/or third-parties in such investigation; and (5) disclose information about any user’s use of the Services in connection with law enforcement investigation of alleged illegal activity, or in response to a lawful court order or subpoena.</p>
            <h2>PROPRIETARY RIGHTS</h2>
            <p>The Services are the proprietary property of Covera and/or its third-party licensors, and are protected by U.S. and foreign copyright, trademark and other intellectual property laws. As used in these Terms, <strong>“Content”</strong> means all text, information, materials, images, photos, graphics, artworks, logos, videos, audios, directories, listings, databases, search engines and other content. All Content owned by Covera or its licensor, and shall remain our property or the property of our licensors. No rights to the Content are granted to you other than as expressly set forth in these terms.</p>
            <p>Subject to your compliance with these Terms, we grant you a revocable, conditional and limited license to access and use the Services solely for your own personal and non-commercial use in accordance with applicable laws. If the Services permit you to download or print any Content, such Content may be used only by you and only in connection with this purpose. You may not copy or distribute any such Content. This license is personal to you and is not transferable or assignable to others, and may be revoked and terminated by us at any time and for any reason (including, without limitation, if you violate these Terms or any applicable law). We reserve all rights not expressly granted herein.</p>
            <p>The trademarks, logos and service marks appearing on the Services, including, but not limited to, the trademarks “Covera Health”, “Covera” and Covera’s logo, are trademarks and service marks of Covera Health, Inc. Other company, product, and service names and logos used and displayed on this Site may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to us. You should not construe anything on the Services as granting, by implication or otherwise, any license or right to use any of trademarks and service marks displayed on the Services, without our prior written permission in each instance. Any unauthorized use, reproduction or distribution of the Services is strictly prohibited and may result in civil and/or criminal penalties.</p>
            <h2>PROHIBITED USES</h2>
            <p>You must not:</p>
            <ul>
                <li>Use the Services except as expressly authorized in these Terms;</li>
                <li>Copy, reproduce, sell, rent, lease, distribute, transfer, modify or make derivative works from the Content or any
                    other aspect of the Services;</li>
                <li>Remove, alter or tamper with any copyright, trademark or other proprietary rights or legal notices contained in any
                    Content obtained through the Services;</li>
                <li>Decompile, disassemble, reverse engineer, or otherwise attempt to discover or derive any proprietary software code
                    or information associated with the Services;</li>
                <li>Interfere in any way with the operation of the Services or any server, network or system associated with the
                    Services, including, without limitation: hacking, mail-bombing, flooding, overloading, or making “denial of service”
                    attacks; probing, scanning or testing the vulnerability of the Services or any server, network or system associated
                    with the Services; breaching or circumventing firewall, encryption, security or authentication routines; accessing
                    data not intended for you, or accessing another’s account that you are not expressly authorized to access;
                </li>
                <li>Use any automated program, tool or process (including, without limitation, web crawlers, scrapers, robots, bots, spiders, and automated scripts) to access the Services or any server, network or system associated with the Services, or to extract, collect, harvest or gather Content from the Services;</li>
                <li>Frame or otherwise create a browser or border environment around any page or Content of the Services, or deep-link to any internal page or area of the Services; or</li>
                <li>Make any other use of the Services that violates these Terms or any applicable law.</li>
            </ul>
            <h2>FEEDBACK</h2>
            <p>The Services may offer you the opportunity to provide us with your comments, suggestions, ideas or other feedback (together, <strong>“Feedback”</strong>).
                If you choose to provide us with Feedback, you agree that we have an unrestricted right to use it, and you are not entitled to receive any compensation.</p>
            <h2>DISCLAIMER OF WARRANTIES</h2>
            <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, COVERA HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SERVICES (INCLUDING ALL CONTENT), WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR TITLE, AND ALL WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, COVERA DOES NOT WARRANT THAT: (A) THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; (B) ANY DEFECT OR ERROR WILL BE CORRECTED; (C) THE SERVICES WILL BE SECURE AND FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; (D) ANY CONTENT PROVIDED THROUGH THE SERVICES WILL BE CURRENT OR ACCURATE; OR (E) THAT A PARTICULAR PRODUCT OR SERVICE YOU SEE ON THE SERVICES WILL BE AVAILABLE TO YOU. YOUR USE OF THE SERVICES IS ENTIRELY AT YOUR OWN RISK. FURTHER, YOU UNDERSTAND AND AGREE THAT ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS USED AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT.</p>
            <h2>LIMITATIONS OF LIABILITY</h2>
            <p>COVERA’S LIABILITY TO YOU IS LIMITED AS SET FORTH BELOW, EXCEPT AND ONLY THE TO EXTENT THAT ANY SUCH LIMITATIONS ARE PROHIBITED BY APPLICABLE LAW:</p>
            <p>IN NO EVENT SHALL COVERA, ITS AFFILIATES, SUBSIDIARIES, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ADVISORS AND CONSULTANTS (COLLECTIVELY, THE <strong>“COVERA PARTIES”</strong>) BE LIABLE TO YOU (WHETHER UNDER CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY OR ANY OTHER LEGAL OR EQUITABLE THEORY) FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF USE, DATA OR INFORMATION OF ANY KIND) ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES. IF APPLICABLE LAW PROHIBITS DISCLAIMER OF DIRECT DAMAGES, COVERA PARTIES’ AGGREGATE LIABILITY TO YOU FOR DIRECT DAMAGES ARISING OUT OF OR RELATING TO THIS AGREEMENT SHALL BE LIMITED TO FIFTY U.S. DOLLARS ($50). THE FOREGOING LIMITATIONS WILL APPLY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. NOTE THAT BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES OR TOTAL LIABILITY, THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
            <h2>USER INDEMNITY</h2>
            <p>YOU AGREE TO INDEMNIFY AND HOLD HARMLESS COVERA PARTIES FROM AND AGAINST ANY AND ALL CLAIMS, ACTIONS, CAUSES OF ACTION, SUITS, LIABILITIES, DAMAGES, LOSSES, COSTS AND EXPENSES (INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES AND LEGAL COSTS) ARISING OUT OF OR RELATING TO: (1) YOUR USE OF, YOUR INABILITY TO USE, OR YOUR RELIANCE UPON, THE SERVICES OR ANY OF ITS COMPONENTS OR ANY OTHER PRODUCTS OR SERVICES OF COVERA; AND/OR (2) YOUR VIOLATION OF ANY OF THESE TERMS OR ANY APPLICABLE LAW.</p>
            <h2>VIOLATIONS; TERMINATION</h2>
            <p>You acknowledge and agree that: (i) Covera may at any time terminate your access to the Services, in whole or in part, if Covera determines, in its sole judgment, that you have violated these Terms or any applicable law; (ii) such termination may be effected without prior notice; and (iii) in the event of such termination, Covera may immediately deactivate and terminate your account and remove (or block your further access to) all your Submissions stored in or associated with your account, without any liability to you.</p>
            <h2>INTERNATIONAL USE</h2>
            <p>The Services are intended for use in the United States only. All matters relating to our Services are governed by the laws of the state of New York in the United States. If you visit our Services or contact us from outside of the United States, please be advised that (i) any information you provide to us or that we automatically collect will be transferred to the United States; and (ii) that by using our Services or submitting information, you explicitly authorize its transfer to and subsequent processing in the United States in accordance with these Terms.</p>
            <h2>LINKS TO THIRD PARTIES</h2>
            <p>The Services may contain links to third party websites, products and/or services (<strong>“Linked Third Party Sites”</strong>). Such links do not constitute or imply Covera’s approval, sponsorship or endorsement of any Linked Third Party Sites. Covera is not responsible for and makes no representations or warranties, express or implied, regarding any Linked Third Party Sites. You access and use Linked Third Party Sites solely at your own risk. When you access and use a Linked Third Party Sites, you are subject to the terms and conditions of use and privacy policy for such Linked Third Party Sites. Any rights, claims or actions you may have in respect of a Linked Third Party Site can only be brought directly against the provider of that Linked Third Party Site.</p>
            <h2>MISCELLANEOUS</h2>
            <ul>
                <li><strong>Notice</strong>. Covera may provide you with notices, including those regarding changes to the Terms, by email, postings on the Services, or as permitted in any applicable Provider Agreement.</li>
                <li><strong>Governing Law</strong>. You and Covera agree that these Terms and any claim or dispute between you and Covera relating to your use of the Services will be governed by and construed in accordance with the laws of the state of New York, without regard to its conflict of laws provisions.</li>
                <li><strong>Waiver</strong>. A provision of the Terms may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of Covera to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision.</li>
                <li><strong>Severability</strong>. If any provision of the Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from the Terms and shall not affect the validity and enforceability of any remaining provisions.</li>
                <li><strong>Independent Contractor</strong>. You agree that no joint venture, partnership, employment, or agency relationship exists between you and Covera as a result of the Terms or use of the Services.</li>
                <li><strong>Headings</strong>. The heading references herein are for convenience purposes only, do not constitute a part of the Terms, and shall not be deemed to limit or affect any of the provisions hereof.</li>
                <li><strong>Disclosures</strong>. The Services are offered by Covera Health, Inc., located at the contact information located at the end of these Terms. If you are a California resident, you may have this same information emailed to you by sending a letter to the foregoing address with your email address and a request for this information.</li>
            </ul>
            <h2>Questions and Comments</h2>
            <p>If you have a question or comment regarding the Services, please contact us via email at <a className="hyper-link" href="mailto:contactus@coverahealth.com">contactus@coverahealth.com</a> or at the address below. Your input is valuable to us.</p>
            <p>Covera Health<br />330 Hudson St,<br />15th Floor<br />New York, NY 10013</p>

        </div>
    )
}

export default TermsAndConditions;