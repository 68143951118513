import React, { useReducer, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Toolbar } from 'primereact/toolbar';
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from "react-router-dom";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Badge } from 'primereact/badge';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';
import 'primeicons/primeicons.css';
import './index.css'
import { ReferralService } from './ReferralService';
import { 
    getExamStatusFilter, ReferralActions, escapeHtml, 
    compareArray, getModality 
} from "../../utils/Utils";
import ActionComponent from './Actions'
import moment from 'moment'
import PropTypes from 'prop-types';
import { ProgressSpinner } from 'primereact/progressspinner';
import UploadForm from "./upload/UploadForm";
import { useUploadQueueContext } from "./upload/UploadQueueContextApi";
import { UploadStatusEnum } from "./upload/UploadConfig";


const init = initialState => initialState;
const TIME_INTERVAL_API_CALL_IN_MIN = 5
const TEXT = {
    ONLY_ONE_STUDY_CAN_UPLOAD : "Only one study can be uploaded at a time.",
    FAILED_PLEASE_TRY_AGAIN: "Failed to upload Study. Please try again.",
    UPLOAD_STUDY_DICOM_REPORT : "Upload study DICOM and Report.",
    VIEW                      : 'VIEW',
    CLOSE                     : 'CLOSE',
    SUCCESS                   : 'SUCCESS'
}

const reducer = (state, { type, payload, totalRecords }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, 
                first: payload.first, 
                rows: payload.rows,
                sortField: payload.sortField,
                sortOrder: payload.sortOrder 
            };
        case "dataLoaded":
            return { ...state, results: payload, loading: false, totalRecords: totalRecords };
        default:
            throw new Error();
    }
};

function Table(props) {
    const initialState = {
        results: Array.from({ length: 5 }),
        loading: true,
        first: 1,
        rows: 25,
        totalRecords: 0,
        orderby: 4,        
        sortField: "Auth Date",
        sortOrder: -1
    };

    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { uploadContextState, refreshTable, setRefreshTable }  = useUploadQueueContext()
    const { results, loading, rows, first, totalRecords, sortField, sortOrder } = state;
    const [selectedExams, setSelectedExams] = useState([]);
    const globalStatusFilter = getExamStatusFilter(true, props.apiConfig.isAmbra);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [disableAction, setDisableAction] = useState(true);
    const [tableHeight, setTableHeight] = useState('200px'); // setting default height
    const examStatuses = getExamStatusFilter(false, props.apiConfig.isAmbra);
    const [actionDialog, setActionDialog] = useState(false);
    const [actionTitle, setActionTitle] = useState(null);
    const toast = useRef(null);
    const [currentPageVal ,setCurrentPageVal] = useState(1);
    const [facilityNames, setFacilityNames] = useState(null);
    const [selectedFacilityNames, setSelectedFacilityNames] = useState(undefined);
    const [filterCount, setFilterCount] = useState(0)
    const [filterLabel, setFilterLabel] = useState("Apply filter")
    const [selectedModality, setSelectedModality] = useState([]);
    const modality = getModality();
    const [currentExam, setCurrentExam] = useState(null);
    const [uploadFormData, setUploadFormData] = useState()

    const [newFilter, setNewFilter] = useState({
        facilityFilter : [],
        statusFilter: [],
        modalityFilter: []
    })
    const [newFilterCount, setNewFilterCount] = useState(0)

    const op = useRef(null);
    useEffect(()=>{
        if (refreshTable){
            refreshPage();
            setRefreshTable(false)
        }
    }, [refreshTable])
    useEffect(() => {
        const fetchData = async () => {
            await ReferralService.getFacilityNames().then((data) => {
                setFacilityNames(data["facility_names"])
                setSelectedFacilityNames([])
            });
          };

        fetchData();
    }, []);

    useEffect(() => {
        if(loading  === false )return;
        const fetchReferralList = (facility_Names,modality) => {
            let filters = {
                limit: rows,
                offset: first === 1 ? first - 1 : first,
                orderby: '"' + sortField + '"',
                sort_order: sortOrder < 0 ? "DESC" : "ASC",
                exam_filter: selectedStatus,
                search_filter: searchKeyword.toLowerCase(),
                facility_names: facility_Names,
                modality: modality,
            }
    
            ReferralService.getReferralList(filters).then((response) => {
                if (response) {
                    dispatch({ type: "dataLoaded", payload: response['data'], totalRecords: response['recordsTotal'] });
                } else {
                    dispatch({ type: "dataLoaded", payload: [], totalRecords: 0 });
                }
                calcTableHeight();
            });
        }
        if(selectedFacilityNames !== undefined && selectedModality !== undefined){
            fetchReferralList(selectedFacilityNames, selectedModality);
        }
        
       
    }, [selectedFacilityNames, searchKeyword, rows, first, sortField, sortOrder, selectedStatus, loading, selectedModality]);

    window.addEventListener('resize', () => {
        calcTableHeight()
    });

    useEffect(() => {
        let count = 0
        if (compareArray(selectedStatus, newFilter.statusFilter)) {
            count++
        }

        if (selectedFacilityNames !== undefined && compareArray(selectedFacilityNames, newFilter.facilityFilter)) {
            count++
        }

        if (selectedModality !== undefined && compareArray(selectedModality, newFilter.modalityFilter)) {
            count++
        }

        setNewFilterCount(count)

        let overAllFilter = 0;
        if (selectedFacilityNames !== undefined && selectedFacilityNames.length > 0) {
            overAllFilter++
        }

        if (selectedModality !== undefined && selectedModality.length > 0) {
            overAllFilter++
        }

        if (selectedStatus.length > 0) {
            overAllFilter++;
        }
        setFilterCount(overAllFilter)

        if (newFilterCount === 1) {
            setFilterLabel("Apply 1 filter")
        } else if (newFilterCount > 1) {
            setFilterLabel("Apply " + newFilterCount + " filters")

        } else {
            setFilterLabel("Apply filters")
        }

    }, [newFilter, newFilterCount, selectedStatus, selectedFacilityNames, selectedModality]);

    const calcTableHeight = () => {
        if (window.getComputedStyle(document.body).overflow === 'hidden') {
            const scrollbody = document.querySelectorAll('.p-toolbar');
            let coeBody = document.querySelectorAll('.coe');
            if (scrollbody && scrollbody.length>0 && coeBody && coeBody.length >0 ) {
                let scrollBodyRect = scrollbody[0].getBoundingClientRect();
                let coeBodyRect = coeBody[0].getBoundingClientRect();
                let height = (coeBodyRect.top - (scrollBodyRect.bottom * 2) / 1.5 );
                setTableHeight(height + 'px')  
            }
        }else{
            setTableHeight(null)
        }  
    }

    const onSort = (event) => {    
        dispatch({ type: "onPage", payload: event })
    };
    const getTimeDifference = (dateVal) => {
        let difference = new Date() - new Date(dateVal)
        difference = difference / (1000*60);
        return difference >=TIME_INTERVAL_API_CALL_IN_MIN
    }

    
    const hideDialog = () => {
        setActionDialog(false);               
        if(actionTitle === ReferralActions.StudyUpload){
            let requestData = {
                'exam_id': currentExam.exam_id,
                'action_sub_type': TEXT.CLOSE,
                'upload_event': {
                  'status': TEXT.SUCCESS
                }
              }            
            ReferralService.auditUploadEvent(requestData); 
        }        
    };

    const loadingTemplate = (options, colm) => {
        if (loading) {
            return (<Skeleton></Skeleton>);
        } else if (options) {
            let c = options[colm.field]?.split(':');
            let s = <p><span  style={{fontWeight: "bold"}}>{c[0]}{c[0] && c[1] && ":"} </span>{c[1]}</p>
            return s;
        } else {
            return '';
        }
    }

    const loadingHyperLinkTemplate = (options, colm) => {
        if (loading) {
            return (<Skeleton></Skeleton>);
        } else if (options) {
            return <Link to= {'exams/'+ options['exam_id']}
            target='_blank'>
                {options[colm.field]}     
            </Link>
        } else {
            return '';
        }
    }
    
    const loadingAuthorizationdData = (options, colm) =>{
        if (loading) {
            return (<Skeleton></Skeleton>);
        } else if (options) {
            return <div>
                {
                    <div>
                        <b>ID:</b> {loadingHyperLinkTemplate(options, colm)} <br />
                    </div>
                }
                {
                    options?.['Insurance Carrier'] && (<div>
                        <div><b>Carrier:</b> {options['Insurance Carrier'].trim()}</div>
                    </div>)
                }
            </div>
        } else {
            return '';
        }
    }

    const loadingReferralDoctorTemplate = (options) => {
        return loading ? (<Skeleton />) : (
            <div>
                {
                    options?.['Referring Doctor Name'].trim() && (<div>
                        <div>{options['Referring Doctor Name'].trim()}</div>
                    </div>)
                }
                {
                    options?.['Referring Doctor Npi'].trim() && (<div>
                        <div><b>NPI:</b> {options['Referring Doctor Npi'].trim()}</div>
                    </div>)
                }
                {
                    options?.['Referring Doctor Phone'].trim() && (<div>
                        <div><b>Phone:</b> {options['Referring Doctor Phone'].trim()}</div>
                    </div>)
                }
            </div>
        )
    }

    const examNotesBodyTemplate = (options) => {
        if (loading) {
            return (<Skeleton></Skeleton>);
        }
        if (options['exam_notes']) {
            return <div>
                <Link to={'exams/' + options['exam_id']}
                    target='_blank'>
                    <Tooltip target=".notes-icon" className="notes-tooltip"/>
                    <i  className="pi pi-align-left notes-icon"
                        data-pr-tooltip ={options['exam_notes']}
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                    ></i>
                </Link>

            </div>


        } else {
            return <Link to={'exams/' + options['exam_id']}
                target='_blank'>
                <Tooltip target=".notes-icon-disabled" className="notes-tooltip" />

                <i data-pr-tooltip="No provider notes"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    className="pi pi-align-left notes-icon-disabled"></i>
            </Link>

        }


    }
    

    const loadingDateTemplate = (options, colm) => {
        if (loading) {
            return (<Skeleton></Skeleton>);
        } else if (options && colm.field && options[colm.field]) {
            return moment.utc(options[colm.field]).format('DD-MMM-YYYY');
        } else {
            return '';
        }
    }

    const updateButtonClick = (e) => {
        setActionTitle(ReferralActions.UpdateExam)
        setActionDialog(true); 
    }  

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2 update-exam" title="Select exams to update" >
                <Button disabled={disableAction} label="Update Exams" 
                    className={disableAction ? "p-button-actions-disabled" : "p-button-actions"} 
                    onClick={updateButtonClick} data-testid="update-exam-btn"/>
            </div>
        );
    };

    const customFacilityNameItemSelectdTemplate = (filterName) => {
        return (<div className="facility-label-placeholder">
            Filter by {filterName}
        </div>)
    }
    
    const getFacilitySelectedItemLabel = () => {
        if (facilityNames?.length === newFilter.facilityFilter?.length) {
            return "All facilities";
        } else if (newFilter.facilityFilter?.length > 1) {
            return "{0} facilities selected";
        } else if (newFilter.facilityFilter?.length === 1) {
            return "{0} facility selected";
        } else {
            return "Filter by facility"
        }
    }

    const getStatusLabel = () => {
        if (examStatuses?.length === newFilter.statusFilter?.length) {
            return "All statuses";
        } else if (newFilter.statusFilter?.length > 1) {
            return "{0} statuses selected";
        } else if (newFilter.statusFilter?.length === 1) {
            return "{0} status selected";
        } else {
            return "Filter by exam status"
        }
    }

    const getModalityLabel = () => {
        if (modality?.length === newFilter.modalityFilter?.length) {
            return "All modalities";
        } else if (newFilter.modalityFilter?.length > 1) {
            return "{0} Modalities selected";
        } else if (newFilter.modalityFilter?.length === 1) {
            return "{0} modality selected";
        } else {
            return "Filter by modality"
        }
    }

    const refreshPage = () => {
        setCurrentPageVal(1)

        let pageState = initialState;
        if (state?.rows && state?.sortOrder && state?.sortField) {
            pageState.rows = state.rows;
            pageState.sortOrder = state.sortOrder;
            pageState.sortField = state.sortField;
        }

        dispatch({ type: "onPage", payload: pageState })
    }

    const clearFilters = () => {
        setNewFilter({
            facilityFilter : [],
            statusFilter: [],
            modalityFilter: []
        })
        setSelectedFacilityNames([])
        setSelectedStatus([])
        setSelectedModality([])
        setFilterCount(0);
        if(op.current){
            op.current.hide()
        }
        refreshPage()
    }

    const applyFilter =() => {
        setSelectedStatus(newFilter.statusFilter)
        setSelectedFacilityNames(newFilter.facilityFilter)
        setSelectedModality(newFilter.modalityFilter)
        setNewFilterCount(0)
        if(op.current){
            op.current.hide()
        }
        refreshPage();

    }

    const handleFilterChange = (e, filter_name) =>{
        setNewFilter(prevState => ({
            ...prevState,
            [filter_name]:e.value
        }))
        
    }

    const getFilterButtonClassName = () =>{
        if(filterCount>0){
            return "filters-available"
        } else{
            return "filters-not-available"
        }
    }

    const customCloseIcon = <i className="pi pi-check"/>

    const getFilterTitle = () => {
        if (filterCount === 1) {
            return "1 filter is applied"
        } else if (filterCount > 1) {
            return filterCount + " filters are applied"
        } else {
            return "Filter the authorizations list"
        }
    }
    
    const rightToolbarTemplate = () => {
        return (
            <div className="search-filter-div"> 
                <div className="card flex justify-content-center">
                <div className="flex flex-wrap gap-2">
                    <Button className={classNames(getFilterButtonClassName(),"filter-menu-btn")} 
                        type="button" 
                        icon="custom-filter-icon" 
                        onClick={(e) => op.current.toggle(e)}
                        data-testid="filter-menu"
                        title={getFilterTitle()}
                    >
                        <img src = {filterCount===0 && "/img/filter-list.png" || "/img/filter-list-white.png" } className="custom-filter-icon" alt="Filter Upload"></img>
                        <span>Filters</span>
                        {filterCount > 0 && <Badge value={filterCount} severity="danger"></Badge>}
                    </Button>
                    
                    </div>
                    <OverlayPanel ref={op} className="filters-panel">
                        <div className="filter-menu">
                            <span className= {facilityNames && facilityNames.length > 1 ? "p-label" : "display-none"}>
                                <MultiSelect
                                    id="selected_facility"
                                    value={newFilter.facilityFilter}
                                    filter
                                    onChange={(e) => handleFilterChange(e,'facilityFilter')}
                                    options={facilityNames}
                                    optionLabel="name"
                                    maxSelectedLabels={0} className="p-actions-spacing multiselect-div multiselect-filter"
                                    display="chip"
                                    selectedItemsLabel={getFacilitySelectedItemLabel()}
                                    selectedItemTemplate={customFacilityNameItemSelectdTemplate('facility')}
                                    resetFilterOnHide = {true}
                                    data-testid='facility-filter'
                                    title = 'Please select facility name'
                                    closeIcon = {customCloseIcon}
                                />
                            </span>
                            <div>
                                <MultiSelect
                                    id="selected_status"
                                    value={newFilter.statusFilter}
                                    filter
                                    onChange={(e) => handleFilterChange(e,'statusFilter')}
                                    options={globalStatusFilter}
                                    optionLabel="displayText"
                                    maxSelectedLabels={0} className="p-actions-spacing multiselect-div multiselect-filter"
                                    display="chip"
                                    selectedItemsLabel={getStatusLabel()}
                                    selectedItemTemplate={customFacilityNameItemSelectdTemplate('exam status')}
                                    resetFilterOnHide = {true}
                                    data-testid='status-filter'
                                    title = 'Please select exam status'
                                    closeIcon = {customCloseIcon}
                                />
                            </div>
                            <div>
                                <MultiSelect
                                    id="selected_modality"
                                    value={newFilter.modalityFilter}
                                    filter
                                    onChange={(e) => handleFilterChange(e,'modalityFilter')}
                                    options={modality}
                                    optionLabel="value"
                                    maxSelectedLabels={0} className="p-actions-spacing multiselect-div multiselect-filter"
                                    display="chip"
                                    selectedItemsLabel={getModalityLabel()}
                                    selectedItemTemplate={customFacilityNameItemSelectdTemplate('modality')}
                                    resetFilterOnHide = {true}
                                    data-testid='modality-filter'
                                    title = 'Please select modality'
                                    closeIcon = {customCloseIcon}
                                />
                            </div>
                            <div>
                                <div className="apply-filter">
                                    <Button label={filterLabel}
                                            disabled={newFilterCount===0}
                                            className="filters-available apply-filter-button"
                                            onClick={applyFilter} data-testid="apply-filter-btn"/>
                                    <Button label="Clear filters" 
                                            disabled={newFilterCount===0 && filterCount ===0}
                                            className="apply-filter-button clear-filters-btn"
                                            onClick={clearFilters} data-testid="clear-filter-btn"/>
                                </div>
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
                
                <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText title="Search by Patient Name or Auth number or CPT" className="search-filter" type="search" onInput={(e) => {
                        setSearchKeyword(e.target.value.trim())
                        refreshPage();
                    }                        
                }
                placeholder="Search..." 
                data-testid= "search_exam"/>
            </span>
        </div>

        );
    };

    const highlightItem = (data) =>{
        setTimeout(function () {
            data.selectedExams.forEach(item => {
                item['highlight'] = false;
            });

            // Refresh the list
            setSelectedExams(null);
            dispatch({ type: "onPage", payload: state })
            setDisableAction(true);

        }, 3000);
    }
    const updateExamInfo = (data) => {
        // Close the action dialog
        if (data.closeOnSuccess) {
            setActionDialog(false)
        }

        data.selectedExams.forEach(item => {

            // Enable the property to highlight the row
            item['highlight'] = true;

            // Update the exam status in UI
            if (data.exam_status)
                item['Prov Status'] = data.exam_status;

            // followup_date has the local date
            if (data.followup_date) {
                item['Follow-up Date'] = data.followup_date;
            }
        });

        // Set the selected exams to raise the row highlight event
        setSelectedExams(data.selectedExams);

        // Wait for the timeout to update the changes in database
        data['exam_id_list'] = data.selectedExams.map((x) => x['exam_id']);
        ReferralService.submitAuthInfo(data).then((response) => {
            if (response) {
                displayToastMessage('success', 'Changes saved successfully!');
            } else {
                displayToastMessage('error', 'Failed to update auth info!');
            }
            highlightItem(data)
        });

        calcTableHeight();
    };

    const getStatusValue = (rowData, field) => {
        if(field && rowData && rowData[field]){
            return rowData[field];
        } 
        return 'NEW';
    }

    const statusBodyTemplate = (rowData, column) => {
        return loading ? (<Skeleton />) :
            (
                <Dropdown
                    data-testid="status-dropdown"
                    value={getStatusValue(rowData, column.field)}
                    options={examStatuses} optionLabel="displayText" optionValue="value"
                    className='exam-cell-dropdown'
                    title="Please mark the list item as Complete once the exam has been interpreted by a radiologist."
                    onChange={(e) => {
                        let data = {
                            exam_status: e.value,
                            selectedExams: [rowData]
                        }

                        updateExamInfo(data);
                    }}
                />
            );
    }

    const getUploadQueue = (examid) => (uploadContextState?.queue ? uploadContextState?.queue.find(({ id }) => id === examid) : null)

    const studyUploadButtonClick = (rowData) => {
        if (rowData?.exam_id) {
            setCurrentExam(rowData)
            const initFormState = {
                accessionNumber: null,
                dicom: null,
                report: null,
                accept: null
            }            
            const currentQueue = getUploadQueue(rowData?.exam_id) 
            if(currentQueue){
                const { accessionNumber, fileInfo } = currentQueue 
                setUploadFormData({
                    accessionNumber: accessionNumber,
                    dicom: fileInfo[0].file,
                    report: fileInfo?.[1]?.file,
                    accept: true
                })
            }else setUploadFormData(initFormState)
            
            setActionTitle(ReferralActions.StudyUpload)
            setActionDialog(true);
            let requestData = {
                "exam_id" : rowData?.exam_id,
                'action_sub_type': TEXT.VIEW,
                'upload_event': {
                  'status': TEXT.SUCCESS
                }
              }
             
            ReferralService.auditUploadEvent(requestData);
        }
    }
    const getUploadStatus = (rowData, currentQueue) => {        
        let statuses = props?.apiConfig?.report_in_dicom ? [rowData?.['DICOM Upload Status']] : [rowData?.['DICOM Upload Status'], rowData?.['Report Upload Status']]
        if (currentQueue)
            statuses = currentQueue?.fileInfo?.map(res => res['status'])
        
        let status = UploadStatusEnum.INPROGRESS
        if (statuses.every(stat => !stat))
            status = ""
        else if (statuses.every(stat => stat === UploadStatusEnum.COMPLETED))
            status = UploadStatusEnum.COMPLETED
        else if (statuses.every(stat => stat === UploadStatusEnum.QUEUED))
            status = UploadStatusEnum.QUEUED
        else if (statuses.includes(UploadStatusEnum.FAILED))
            status = UploadStatusEnum.FAILED
        else if(statuses.includes(UploadStatusEnum.INPROGRESS))
            status = UploadStatusEnum.INPROGRESS
        else if(statuses.includes(UploadStatusEnum.STARTED))
            status = UploadStatusEnum.STARTED
        return status
    }

    const uploadButtonData = {
        'QUEUED': {'toolTip': 'Pending upload', icon: 'pi-clock', disable: false},
        'COMPLETED': {'toolTip': 'Study was successfully uploaded.', icon: 'pi-check', disable: true}

    }    
    const uploadTemplate = (rowData, column) => {
        const currentQueue = getUploadQueue(rowData?.exam_id);
        const status = getUploadStatus(rowData, currentQueue)
        let difference = rowData?.["Study Upload Lastupdated"] ? getTimeDifference(rowData?.["Study Upload Lastupdated"]) : null
        if (([UploadStatusEnum.INPROGRESS, UploadStatusEnum.STARTED].includes(status) && difference && !currentQueue) || status === UploadStatusEnum.FAILED) {
            let titleText = TEXT.FAILED_PLEASE_TRY_AGAIN 
            return <div title={titleText}>
                <Button
                    icon='pi pi-exclamation-triangle'
                    className='icon-btn-upload-alert'
                    aria-label='Upload-popup'
                    onClick={() => studyUploadButtonClick(rowData)}
                />
            </div>
        }
         else if (uploadButtonData?.[status]){
            if (currentQueue){
                return (<div title={uploadButtonData?.[status].toolTip}><Button
                        icon={`pi ${uploadButtonData?.[status].icon}`}
                        className='icon-btn-upload'
                        aria-label='Upload-popup'
                        disabled={uploadButtonData?.[status].disable}
                        onClick={() => studyUploadButtonClick(rowData)}
                    /></div>)
            }
            else
                return (<i title={uploadButtonData?.[status].toolTip} className={`pi ${uploadButtonData?.[status].icon} toggle-btn-disabled`}></i>)
        }
        else if(status === UploadStatusEnum.INPROGRESS && currentQueue){
            return (<Button
                className='icon-btn-upload' 
                title="Study upload is in progress for this exam." onClick={()=>studyUploadButtonClick(rowData)} onKeyDown={()=>{}}>
                <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
            </Button>)
        }
        else if(status === UploadStatusEnum.INPROGRESS){
            return <i title='Upload In Progress' className="pi pi-cloud-upload toggle-btn-disabled"></i>
        }
         else {
            return (
                <div title={TEXT.UPLOAD_STUDY_DICOM_REPORT}>
                    <Button
                        icon='pi pi-cloud-upload'
                        className='icon-btn-upload'
                        aria-label='Upload-popup'
                        onClick={() => studyUploadButtonClick(rowData)}
                    />
                </div>)
            
        }
    }
    

    const rowClassName = (data) => {
        if (data) {
            return data['highlight'] ? 'row-highlight' : ''
        }
        return ''
    };

    const[expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (rowData) => {
        const isExpanded = expandedRows.includes(rowData);
        if (isExpanded) {
            setExpandedRows(expandedRows.filter((row) => row !== rowData));
        } else {
            setExpandedRows([...expandedRows, rowData]);
        }

        // Audit the selected row
        let request_data = {
            "exam_id_list": [rowData.exam_id],
            "column_info": { "name": "designated_reader", "action": isRowExpanded(rowData) ? "Collapse" : "Expand" }
        }

        ReferralService.auditAuthInfo(request_data);
    };

    const isRowExpanded = (rowData)=>expandedRows.includes(rowData);

    const getRadListTemplate = (rowData, field) => {
        if(field && rowData && rowData[field]){
            let radRows = rowData[field];
            if(rowData[field].length > 3) {
                if(!isRowExpanded(rowData)){
                    radRows = rowData[field].slice(0,3)
                }               
            }

            radRows = formatRADList(radRows);

            return (
                radRows.map((item) => (
                    <li key={item}>{item}</li>
                ))
            );
        }
    }

    const formatRADList = (radListObj) => {
        if (radListObj) {
            let radNames = [];
            radListObj.map((item) => {
                radNames.push(escapeHtml(item));
                return null
            })
            return radNames.sort((a, b) => a.localeCompare(b));
        }
    }

    const displayExpandIcon = (rowData, field) => {
        return rowData?.[field]?.length > 3
    }

    const designatedReaderBodyTemplate = (rowData, column) => {
        return loading ? (<Skeleton />) :
        (
            <div className="rad-list">
                <ul className="rad-list-ul">
                    {
                        getRadListTemplate(rowData, column.field)
                    }
                </ul>
                {displayExpandIcon(rowData, column.field) && <Button title={isRowExpanded(rowData)? "Collapse" : "Expand"} className="icon-btn-upload" icon={isRowExpanded(rowData)? "pi pi-chevron-up" :"pi pi-chevron-down"}  onClick={() => toggleRow(rowData)} data-testid="toggle-btn"/>}
            </div>

        );
    }

    const formatDate=(date) => moment.utc(date).format('DD-MMM-YYYY')
    
    const getFollowupDate = (rowData) => {
        if (rowData?.['Follow-up Date']) {
            return formatDate(rowData['Follow-up Date']);
        }
        return '';
    }

    const handleDateOnChange = (rowData, e) => {
        const data = {
            selectedExams : [rowData],
            exam_status: "", 
            followup_date : moment(e.value).format('YYYY-MM-DD'),
            closeOnSuccess: true,
            exam_id_list : [e.target.id]            
        }
        updateExamInfo(data)
    }

    const followupDateBodyTemplate = (rowData) => {
        return loading ? (<Skeleton />) :
        (
            <div className="">
                <span className="pt-5">{getFollowupDate(rowData)}</span>
                <div className="followup-div">
                  <Calendar
                        id = {rowData['exam_id']}
                        readOnlyInput= {true} className="followup-date"
                        onChange={ (e) => handleDateOnChange(rowData,e)}
                        showIcon
                    />
                </div>
            </div>
        );
    }

    const designatedFacilityTemplate = (rowData) => {
        return loading ? (<Skeleton />) :
        (
            <div>
                <span className="pt-5">{rowData?.['Prov Name']}</span>
                {rowData?.['Prov Address'] &&
                    <ul className="rad-list-ul">
                        <li>{rowData?.['Prov Address'].replace(/,\s*$/, "")}</li>
                    </ul>
                }
            </div>
        );
    }

    const displayToastMessage = (severity, message) => {
        let errorClass= severity === 'error' ? 'error-toast' : 'success-toast';
        let toastMessage = {
            severity: severity,
            life: 3000,
            summary : message,
            className : errorClass
        }
        toast.current.show(toastMessage);
    };
    
    const getPaginatorTemplate = () => {
        if(loading || results?.length === 0){
            return "";
        } 
        return "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink";
    }

    const getCurrentReport = () =>{ 
        let firstRecord = 0;
        let lastRecord = 0;
        if(rows && results?.length !== 0){
            firstRecord = ((currentPageVal * rows)  - rows) + 1; 
            lastRecord = Math.min((firstRecord + rows) - 1, totalRecords); 
        }
        return `Showing  ${firstRecord} to ${lastRecord} of {totalRecords} records`   ; 
    }

    const currentPageReport = getCurrentReport();
    const currentPageTemplate = getPaginatorTemplate();
            
    return (
        <div>
            <Toast ref={toast} position='top-center' />
            <Toolbar id="manage-referral-div" className="mb-4 referral-toolbar" start={leftToolbarTemplate} end={rightToolbarTemplate} ></Toolbar>
            <DataTable
                value={results}
                paginator
                rows={rows}
                rowsPerPageOptions={[10, 25, 50, 100]}
                totalRecords={totalRecords}
                lazy
                first={first}
                onPage={e => {
                    setCurrentPageVal(e.page + 1)
                    dispatch({ type: "onPage", payload: e })
                }}
                onSort={onSort} sortField={sortField} sortOrder={sortOrder}
                selectionMode={'checkbox'} selection={selectedExams} onSelectionChange={(e) => {
                    setSelectedExams(e.value)
                    setDisableAction(e.value.length === 0)
                }}
                scrollable={true} scrollHeight={tableHeight}
                rowClassName={rowClassName}
                className="referral-table"
                paginatorTemplate={currentPageTemplate}
                currentPageReportTemplate={currentPageReport}
            >
                <Column selectionMode="multiple" data-testid='update-exam' className=".w-2-per" title='update-exam'></Column>
                <Column field="Patient Name" sortable header="Patient Name" className="w-10-per" body={loadingHyperLinkTemplate} />
                <Column field="DOB" header="DOB" sortable className="w-6-per" body={loadingDateTemplate} />
                <Column field="Auth Date" sortable header="Auth Date" className="w-6-per" body={loadingDateTemplate} />
                <Column field="Auth Number" header="Authorization" className="w-10-per" body={loadingAuthorizationdData} />
                <Column field="Auth ExpDate" sortable header="Auth Expiry" className="w-6-per" body={loadingDateTemplate} />
                <Column field="CPT with Description" header="CPT & Description" className="w-10-per" body={loadingTemplate} />
                <Column field="Referring Doctor" header="Referring Doctor" className="w-10-per" body={loadingReferralDoctorTemplate} />
                <Column field="Prov Name" header="Designated Facility" className="w-10-per" body={designatedFacilityTemplate} />
                <Column field="Rad List" header="Designated Reader" className="w-10-per" body={designatedReaderBodyTemplate} />
                <Column field="Follow-up Date" sortable header="Follow-up Date" body={followupDateBodyTemplate} className="followup-col w-6-per" />
                <Column field="exam_notes" header="Provider Notes" body={examNotesBodyTemplate} className="exam-col" />
                <Column field="Prov Status" header="Exam Status" className="exam-col" body={statusBodyTemplate} />
                {props.apiConfig.upload_study && <Column field="Upload" header="Upload Study" className="exam-col" body={uploadTemplate} />}
            </DataTable>
            <Dialog visible={actionDialog} header={actionTitle} modal className={`p-fluid ${actionTitle === ReferralActions.UpdateExam ? 'dialog-div': 'dialog-div-upload'}`} onHide={hideDialog} closable={true}>
                {
                actionTitle === ReferralActions.UpdateExam ? <ActionComponent referral={results}
                    selectedExams={selectedExams} setSelectedExams={setSelectedExams}
                    setToast={displayToastMessage} toast={toast}
                    apiConfig={props.apiConfig}
                    submitAuthInfo={updateExamInfo}
                    setDisableAction={setDisableAction}
                /> : 
                    <UploadForm formState={uploadFormData} examDetails={currentExam} setActionDialog={setActionDialog} displayToastMessage={displayToastMessage}/>
                }
            </Dialog>
        </div>
    );
};
Table.propTypes = {
  apiConfig: PropTypes.object.isRequired
 };
export default Table;