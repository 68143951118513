import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Auth } from 'aws-amplify';
import { 
    TEXT, UI_COMPONENT, 
    isFormFieldInvalid, getFormErrorMessage, 
    clearAlert, setAlertMessage, getAuthErrorMessage
} from './CustomAuthUtils'
import { useNavigate } from "react-router-dom";
import { validateEmail, getFormattedEmail, getCookie } from "../../utils/Utils";

/*
AuthChallengeUI - it is a Component.
It contains the following
    - Username & Password UI.
    - Form validation.
    - User Auth Challenge to AWS (Auth.signIn()).
        - Get the UserSession after successfull of AuthChallenge.
        - Displays the Verify Auth Challenge Screen (MFA).
*/
function AuthChallengeUI(props) {
    const [uiComponent, setUIComponent]  = useState('');
    const [authChallengeSubmitted, setAuthChallengeSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    //Initial Form State.
    const authChallengeForm = {
        email: getCookie('email'),
        password: ""
    }

    //Form Validations for AuthChallenge UI
    const authChallengeFormValidation = values => {
        const errors = {};
        if (!values.password || values.password.trim() === '') {
            errors.password = TEXT.PASSWORD_REQUIRED;
        }
        validateEmail(errors, values.email)
        return errors;
    }

    //Defining the AuthChallenge form.
    const formik = useFormik({
        initialValues: authChallengeForm,
        validate: authChallengeFormValidation,
        onSubmit: async (data) => {
            clearAlert(props)
            data && submitAuthChallenge(data)
        }
    });

    /*
    Makes an api call using AWS-AMPLIFY to Submitting Auth Challenge Request.
    After successfull of AuthChallenge, UserSession can received from the response.
        - Assigning the UserSession to a Global State variable to do the Verify Auth Challenge (MFA).
        - Finally, its rendering the Verify Auth Challenge Component.
     */
    const submitAuthChallenge = async (data) => {
        setLoading(true);
         Auth.signIn(data.email.trim(), data.password).then(user => {
            props.setUserSession(user);
            props.setAuthInfo(data);
            setUIComponent(
                user?.challengeName === UI_COMPONENT.NEW_PASSWORD_CHALLENGE ? 
                    UI_COMPONENT.NEW_PASSWORD_CHALLENGE : UI_COMPONENT.VERIFY_CHALLENGE
            );
            setAuthChallengeSubmitted(true);
            formik.resetForm();
        }).catch(err => {
            formik.values.password = '';
            if(err.code  === 'PasswordResetRequiredException'){
                navigate('/resetpassword', { state:{email: data.email.trim()} })
            }else{
                setAlertMessage(props, TEXT.ERROR_CLASS_NAME, getAuthErrorMessage(err.message));
            }
            
        }).finally(()=>{
            setLoading(false)
        });
    }

    useEffect(() => {
        //After successfull AuthChallenge, rendering the Verify Auth Challenge(MFA).
        authChallengeSubmitted && props.setUIComponent(uiComponent);
    });

    //UI Component for Auth Challenge.
    return (
            <div className="card flex justify-content-left form-div">
                <form className="flex flex-column gap-2" onSubmit={formik.handleSubmit} data-testid='auth-form'>
                    <div className='pasword-reset-form-field'>
                        {/* <label htmlFor="input_value" className={classNames({ 'p-error': isFormFieldInvalid('email', formik) })}>{TEXT.EMAIL}</label> */}
                        <InputText
                            id="email"
                            name="email"
                            placeholder={TEXT.EMAIL}
                            value={getFormattedEmail(formik.values.email)}
                            onChange={(e) => {
                                formik.setFieldValue('email', getFormattedEmail(e.target.value));
                            }}
                            className={classNames({ 'p-error': isFormFieldInvalid('email', formik) }, 'email-input desktop-input')}
                            data-testid='email-input'
                        />
                        {getFormErrorMessage('email', formik)}
                    </div>
                    <div className='pasword-reset-form-field  password-field'>
                        {/* <label htmlFor="input_value" className={classNames({ 'p-error': isFormFieldInvalid('password', formik) })}>{TEXT.PASSWORD}</label> */}
                        <Password
                            placeholder={TEXT.PASSWORD}
                            id="password"
                            name="password"
                            value={formik.values.password}
                            onChange={(e) => {
                                formik.setFieldValue('password', e.target.value);
                            }}
                            className={classNames({ 'p-error': isFormFieldInvalid('password', formik) }, 'p-password-cust', 'email-input desktop-input ')}
                            feedback={false}
                            data-testid='password-input'
                        />
                        {getFormErrorMessage('password', formik)}
                    </div>
                    <a className = "forgot_password_link" href="/forgotpassword">Forgot your password?</a>
                    <br/>
                    <Button type="submit" label={TEXT.SIGN_IN} className="signon-btn lgn-pg-btn" loading={loading} />
                </form>
            </div>
    );
}
export default AuthChallengeUI;