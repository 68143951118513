import '../App.css';
import React, { useEffect, useState, useRef } from "react";
import { API_ENDPOINTS } from "../utils/UrlUtils";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { invokePostApiWithoutAuthHeader } from '../utils/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { validateEmail, APP_TITLE, setCookie, getCookie } from '../utils/Utils';

function SSOView() {    
    const [loginError, setLoginError] = useState('');
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);
    const TEXT =  {
        INVITATION_SUCCESS_MESSAGE: 'If your account is valid, you will receive an email with password reset instructions. Please allow a few minutes while the email is in transit.',
        SUCCESS_TOAST: 'success-toast',
        FAILURE_TOAST: 'error-tost',
        ERROR: 'ERROR',
        LOGIN_FAILURE : 'Login failed. Please try again later. If the issue persists, then please reach out to your system administrator for assistance.',
        SUBMIT : 'Continue',
        EMAIL: 'email address',
    }

    useEffect(() => {
        const displayToastMessage = (severity, message) => {
            let errorClass= severity === TEXT.ERROR ? TEXT.FAILURE_TOAST : TEXT.SUCCESS_TOAST;
            let toastMessage = {
                severity: severity,
                life: 3000,
                summary : message,
                className : errorClass
            }
    
            toast.current.clear();
            toast.current.show(toastMessage);
        };
        /* Display the error message */
        if (searchParams.get("error")){
            setLoginError('Login failed. Please try again later. If the issue persists, then please reach out to your system administrator for assistance.');
            navigate('/login')
        }

        /* Display the toast message when navigating from forgot password page */
        if (searchParams.get("rpw")){
            displayToastMessage(TEXT.SUCCESS_TOAST, TEXT.INVITATION_SUCCESS_MESSAGE)
            navigate('/login')
        }
    }, [TEXT.ERROR, TEXT.FAILURE_TOAST, TEXT.INVITATION_SUCCESS_MESSAGE, TEXT.SUCCESS_TOAST, navigate, searchParams]);

    
    

    const ssoForm = {
        email: ""
    }

    const ssoFormValidation = values => {
        const errors = {};
        validateEmail(errors, values.email )
        return errors;
    }

    const submitSSOForm = async (data) => {
        invokePostApiWithoutAuthHeader(API_ENDPOINTS.SSO, data, false).then((response) => {
            if (response.status === 200 && response.data) {                
                if(response.data.url){
                   setCookie('email', data?.email, window.location.hostname);
                    window.location.replace(response.data.url)
                }
                else
                    setLoginError(response.data.message);    
            } else {
                setLoginError(TEXT.LOGIN_FAILURE);                
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const formik = useFormik({
        initialValues: ssoForm,
        validate: ssoFormValidation,
        onSubmit: async (data) => {
            setLoginError('');
            setLoading(true)
            data && submitSSOForm(data)
        }
    });

    const isFormFieldInvalid = (name, formik) => {return !!(formik.touched[name] && formik.errors[name])}
    const getFormErrorMessage = (name, formik) => {
        return isFormFieldInvalid(name, formik) ? <p className="p-error">{formik.errors[name]}</p> : '';
    };

    const renderForm = (formid, inputClass) => {
        return (
            <form id={formid} className="signon-form" onSubmit={formik.handleSubmit} >
                <InputText
                    id="email"
                    name="email"
                    type="email"
                    placeholder={TEXT.EMAIL}
                    value={formik.values.email}
                    onChange={(e) => {
                        formik.setFieldValue('email', e.target.value);
                    }}
                    className={classNames({ 'p-invalid': isFormFieldInvalid('email', formik) }, inputClass)}
                />
                {getFormErrorMessage('email', formik)}
                <Button type="submit" label={TEXT.SUBMIT} className="signon-btn lgn-pg-btn" loading={loading} />
                <p className='error-msg'>{loginError}</p>
            </form>
        )
    }

    return (
        <div className='white-header'>
            <Toast ref={toast} position='top-center' />
            <div className='grid-container flush menu-takeover' id='menu-takeover' style={{ zIndex: '1000', }}>
                <div className="grid-container flush home__top">
                    <div className="top home-top header-image" style={{ backgroundImage: "url(img/homepage-header-1.webp)" }}>
                        <div className='col c4 menu-logo-container' >
                            <a href='https://www.coverahealth.com/'>
                                <img alt='Covera' data-src='img/logo-light.png' className='logo Ls-is-cached lazyloaded' src='img/logo-light.png' />
                            </a>
                        </div>
                        <div className="col c6--md tl--md">
                            <div className='desktop-text' style={{ color: '#fff' }}>
                                <h1 className='sans--x-large fw--300'>{APP_TITLE}</h1>
                                <p className='landing-subtext'>Please enter your email address to continue.</p>
                                {renderForm('desktop-form', 'email-input desktop-input')}
                            </div>
                        </div>
                    </div>
                    <div className='mobile-text' style={{ color: '#005c62' }}>
                        <div className='col cl2 mobile-header'>
                            <h1 className='sans--x-large fw--300'>{APP_TITLE}</h1>
                            <p className='landing-subtext'>Please enter your email address to continue.</p>
                            {renderForm('mobile-form', 'email-input mobile-input')}                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SSOView;