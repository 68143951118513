import React, { useState, useEffect, useRef } from 'react';
import { Button } from "primereact/button";
import { ManageUsersService } from "../manage_users/service/ManageUserService";
import TermsAndConditions from './TermsAndConditions';
import moment from 'moment'
import "./user_agreement.css"

function UserAgreement(props) {    
    const propsData = getPropsData();
    const data = getUserAgreementData();
    const termsTextBox = useRef()

    const [checkboxSelected, setCheckboxSelected] = useState({
        termsCondition : false,
        privacyPolicy : false,
    });

    const handleCheckboxChange = (checkboxName) =>{
        setCheckboxSelected({
            ...checkboxSelected,
            [checkboxName]: !checkboxSelected[checkboxName],
        });    
    };

    useEffect(() => {
        calcTableHeight()
    },)
    const agree =checkboxSelected.termsCondition && checkboxSelected.privacyPolicy

    const handelClick = (e) => {
        ManageUsersService.setUserAgreementAcceptance(getUserId()).then((response) => {
            if (response) {
                if (propsData) {
                    propsData?.hideDialog();
                }
            }
        });  
    }

    function getPropsData() {
        if (props) {
            return props;
        } else {
            return null;
        }
    }

    function getUserAgreementData() {
        if (propsData?.userAgreement == null) {
            return null;
        }
        return propsData.userAgreement;
    }

    function getUserId() {
        if (propsData == null || propsData.userData == null ||
            propsData.userData.signInUserSession == null ||
            propsData.userData.signInUserSession.idToken == null) {
            return null;
        }

        return propsData.userData.signInUserSession.idToken.payload['email'];
    }

    const formatDate = (effectiveDate) => {
        return moment.utc(effectiveDate).format('MMMM DD, YYYY');
    }

    window.addEventListener('resize', () => {
        calcTableHeight()
    });
    
    const calcTableHeight = () => {
        if (window.getComputedStyle(document.body).overflow === 'hidden') {
            const scrollbody = document.querySelectorAll('.p-dialog-content');
            let userAgreementTextBox = document.querySelectorAll('.user-aggrement');
            if (scrollbody && scrollbody.length>0 && userAgreementTextBox && userAgreementTextBox.length >0) {
                let scrollBodyRect = scrollbody[0].getBoundingClientRect();
                let height = (scrollBodyRect.bottom - ((scrollBodyRect.top + 75) * 1.8) / 1.6 );
                termsTextBox.current.style.height = `${Math.round(height)}px`
            }
        }
    }

    return(
        <div className='user-aggrement'>
            <h5 className='agreement-title'>Please review and agree to the latest Terms & Conditions</h5>
            <div ref={termsTextBox} className='terms-condition-txt' >
                <h1 className="sans--large fw--300 pb2 pr10--md">Terms And Conditions</h1>
                {data?.date && <p><strong>Effective {formatDate(data?.date)}</strong></p>}
                <TermsAndConditions/>
                <div className='tc-btn-div'>
                    <div className='agree-checkbox-parent'>
                        <div className='agree-checkbox-container'>
                            <input type="checkbox" className='agree-checkbox' data-testid= 'terms-condtions-checkbox' onChange={ () => {
                                handleCheckboxChange('termsCondition')
                            }}/>
                            <label className='agree-label'>I have read and agree to the Terms And Conditions</label>
                        </div>
                        <div className='agree-checkbox-container'>
                            <input type="checkbox" className='agree-checkbox' data-testid='privacy-policy-checkbox' onChange={ () => {
                                handleCheckboxChange('privacyPolicy')
                            }}/>
                            <label className='agree-label'>I have read and agree to the Privacy Policy</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='continue-btn-div'>
                <div className='tc-btn-container'>
                    <Button label="Continue" disabled = {!agree} className={agree ? "p-button-actions" :  "p-button-actions-disabled"} onClick={handelClick}  data-testid='submit-btn'/>
                </div>
            </div>
        </div>
    )
}

export default UserAgreement;