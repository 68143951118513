import React, { useState } from "react";
import { UI_COMPONENT, initAlertState } from './CustomAuthUtils';
import  AuthChallengeUI from './AuthChallenge';
import MFAChallengeUI from "./VerifyChallenge";
import NewPasswordChallengeUI from "./NewPasswordChallenge";
import { APP_TITLE } from "../../utils/Utils";

/*
CustomAuthFlow - It is a Parent Component.
It displays the form based on the State (uiComponent).
    1.  On First load, it displays the AuthChallengeUI (email, password screen).
    2. Then Renders the MFA Challenge UI(After 1 is completed).
- Finally it navigate to Referral list screen(After both component is completed).
 */
function CustomAuthFlow(props) {    
    const [uiComponent, setUIComponent] = useState(UI_COMPONENT.AUTH_CHALLENGE);
    const [userSession, setUserSession] = useState(null);
    const [authInfo, setAuthInfo] = useState(null);
    const [alertState, setAlertState] = useState(initAlertState);
    const { alertType, alertMessage } = alertState;

    //Rendering the component based on State.
    const renderForm = () => {
        return <div className="custom-auth-container">
            {
                uiComponent === UI_COMPONENT.AUTH_CHALLENGE &&
                <div>
                    <h1 className='sans--x-large fw--300'>{APP_TITLE}</h1>
                    <AuthChallengeUI
                        setUIComponent={setUIComponent}
                        userSession={userSession}
                        setUserSession={setUserSession}
                        setAuthInfo={setAuthInfo}
                        setAlertState={setAlertState}
                    />
                </div>
            }
            {
                uiComponent === UI_COMPONENT.VERIFY_CHALLENGE &&
                <div>
                    <h1 className='sans--x-large fw--300 center-aligned'>Covera Provider Portal</h1>
                    <br />
                    <MFAChallengeUI
                        authInfo={authInfo}
                        setUIComponent={setUIComponent}
                        setUser={props.setUser}
                        userSession={userSession}
                        setUserSession={setUserSession}
                        setAlertState={setAlertState}
                    />
                </div>
            }
            {
                uiComponent === UI_COMPONENT.NEW_PASSWORD_CHALLENGE &&
                <div>
                    <h1 className='sans--x-large fw--300'>Covera Provider Portal</h1>
                    <NewPasswordChallengeUI
                        setUIComponent={setUIComponent}
                        setUser={props.setUser}
                        userSession={userSession}
                        setUserSession={setUserSession}
                        setAuthInfo={setAuthInfo}
                        setAlertState={setAlertState}
                    />
                </div>
            }
            <p className={alertType}>{alertMessage}</p>
        </div>
    }

    //Rendering the component based on State.
    return (
        <div className='white-header'>
            <div className='grid-container flush menu-takeover' id='menu-takeover' style={{ zIndex: '1000', }}>
                <div className="grid-container flush home__top">
                    {/* For desktop version */}
                    <div className="top home-top header-image" style={{ backgroundImage: "url(img/homepage-header-1.webp)" }}>
                        <div className='col c4 menu-logo-container' >
                            <a href='https://www.coverahealth.com/'>
                                <img alt='Covera' data-src='img/logo-light.png' 
                                    className='logo Ls-is-cached lazyloaded' 
                                    src='img/logo-light.png' 
                                />
                            </a>
                        </div>
                        <div className="col c6--md tl--md">
                            <div className='desktop-text' style={{ color: '#fff' }}>
                                {renderForm()}
                            </div>
                        </div>
                    </div>

                    {/* For Mobile version */}
                    <div className='mobile-text' style={{ color: '#005c62' }}>
                        <div className='col cl2 mobile-header'>
                            {renderForm()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomAuthFlow;