import React, { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Auth } from 'aws-amplify';
import { Divider } from 'primereact/divider';
import { 
    TEXT,
    isFormFieldInvalid, getFormErrorMessage, 
    clearAlert, setAlertMessage, 
    AwsAttributes
} from './CustomAuthUtils';
import { API_ENDPOINTS } from "../../utils/UrlUtils"
import { invokePostApi } from "../../utils/api"
import { sha256 } from 'crypto-hash';


/*
NewPasswordChallengeUI - It is a component.
It will displayed only if user state is NEW_PASSWORD_REQUIRED.
There are 3 inputs, Temp password, New password, Confirm password
    - If any required attributes are missing from the UserAttributes,
        - UI will render the required attributes
        - User must be provide all the attribute values.
- Form Validations
- After succesfull password creation, it redirects to the application
- MFA will not be generated in this cases.
 */
function NewPasswordChallengeUI(props){
    const [loading, setLoading] = useState(false);    
    const { requiredAttributes } = props?.userSession?.challengeParam;
    const { email } = props?.userSession?.challengeParam?.userAttributes;
    const strongRegex='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})';
    //Initial Form State.
    const passwordChallengeForm = {
        current_password: "",
        new_password: "",
        confirm_password: ""
    }

    //UI validations
    const passwordChallengeFormValidation = values => {
        const errors = {};
        if (!values.current_password || values.current_password.trim() === '')
            errors.current_password = TEXT.CURRENT_PASSWORD_REQUIRED;        
        if (!values.new_password || values.new_password.trim() === '') 
            errors.new_password = TEXT.NEW_PASSWORD_REQUIRED;
        else if(values.new_password.length < 10) 
            errors.new_password = TEXT.PASSWORD_LENGTH_VALIDATION_MESSAGE;
        if (!values.confirm_password || values.confirm_password.trim() === '') 
            errors.confirm_password = TEXT.CONFIRM_PASSWORD_REQUIRED;
        if (values.new_password !== values.confirm_password.trim()) 
            errors.confirm_password = TEXT.CONFIRM_PASSWORD_UNIQUE;  
        if (values.new_password === values.current_password)          
            errors.new_password = TEXT.CURRENT_NEW_PWD_NOT_SAME;
        return errors;
    }

    const requiredAttributesValidation = (data) => {
        let attrValidation = true
        if (requiredAttributes && requiredAttributes.length > 0){
            requiredAttributes.map((attribute) =>{
                if(!requiredAttributes[attribute] || requiredAttributes[attribute].trim() === ''){
                    setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.FILL_OUT_REQ_ATTRIBUTES);
                    attrValidation = false
                }   
            });            
        }
        return attrValidation;
    }

    //Bind the required attributes values from UI.
    const getRequiredAttributes = () => {
        let customAttributes = {}
        requiredAttributes && requiredAttributes.map((attribute) => {
            customAttributes[attribute] = requiredAttributes[attribute]
        });
        return customAttributes;
    }

    /*
        Submitting change password request using Aws amplify.
        if it is success, navigates to login screen.
     */
    const submitPasswordChallenge = async (data) => {   
        setLoading(true);     
        await Auth.signIn(email, data.current_password).then((userResponse) => {              
            Auth.completeNewPassword(userResponse, data.new_password, getRequiredAttributes()).then(async (userRes) => {
                /*
                    User object has been updated after successfull authentication.
                    Hub.listen -> detects this successfull authentication
                    and automatically navigates to Referrals.
                    MFA is not required for this scenario.
                */
                if(userRes && userRes.signInUserSession){
                    let hashed_password = await sha256(data.new_password)          
                    let post_reset_request = { 
                        'email_id' :  email, 
                        'user_pool_id' : userResponse['pool']['userPoolId'], 
                        'new_password' : hashed_password
                    }
                    invokePostApi(
                        API_ENDPOINTS.CONFIRM_RESET_PWD_EMAIL, post_reset_request, false
                    );
                }else{
                    console.log('Invalid user Sesssion.')
                }
                
                    
            }).catch(err => {
                //props.setUIComponent(UI_COMPONENT.AUTH_CHALLENGE); 
                setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.CHANGE_PASSWORD_ERROR);
            })
        }).catch(err => {
            setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.INVALID_CURRENT_PASSWORD);
        });                        
        setLoading(false);     
    }

    //Defining the AuthChallenge form.
    const formik = useFormik({
        initialValues: passwordChallengeForm,
        validate: passwordChallengeFormValidation,
        onSubmit: async (data) => {
            clearAlert(props)
            if(requiredAttributesValidation(data))
                data && submitPasswordChallenge(data);
        }
    });

    const footer = (
        <>
            <p className="mt-2"><b>Password Requirements</b></p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>Password must contain a lower case letter</li>
                <li>Password must contain an upper case letter</li>
                <li>Password must contain a number</li>
                <li><b>Password must contain a special character</b></li>
                <li><b>Password must have at least 10 characters</b></li>
                <li>Password must not contain a leading or trailing space</li>
            </ul>
        </>
    );

    const renderNewPasswordUI = () => {
        return ( 
            <>
                <span>{TEXT.CHANGE_PASSWORD_LABEL}</span>
                <div className='pasword-reset-form-field'>
                    <Password
                        placeholder={TEXT.Current_OR_TEMP_PASWORD}
                        id="current_password"
                        name="current_password"
                        value={formik.values.current_password}
                        onChange={(e) => {
                            formik.setFieldValue('current_password', e.target.value);
                        }}
                        className={classNames({
                            'p-error': isFormFieldInvalid('current_password', formik)
                        },
                            'p-password-cust', 'email-input desktop-input '
                        )}
                        feedback={false}
                        data-testid='current_password_input'
                    />
                    {getFormErrorMessage('current_password', formik)}
                </div>
                <div className='pasword-reset-form-field  password-field'>
                    <Password
                        strongRegex={strongRegex}
                        placeholder={TEXT.NEW_PASSWORD}
                        id="new_password"
                        name="new_password"
                        value={formik.values.new_password}
                        onChange={(e) => {
                            formik.setFieldValue('new_password', e.target.value);
                        }}
                        className={classNames({
                            'p-error': isFormFieldInvalid('new_password', formik)
                        },
                            'p-password-cust', 'email-input desktop-input '
                        )}
                        footer={footer}
                        data-testid='new_password_input'
                    />
                    {getFormErrorMessage('new_password', formik)}
                </div>
                <div className='pasword-reset-form-field  password-field'>
                    <Password
                        placeholder={TEXT.CONFIRM_PASSWORD}
                        id="confirm_password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={(e) => {
                            formik.setFieldValue('confirm_password', e.target.value);
                        }}
                        className={classNames({
                            'p-error': isFormFieldInvalid('confirm_password', formik)
                        },
                            'p-password-cust', 'email-input desktop-input '
                        )}
                        feedback={false}
                        data-testid='confirm_password__input'
                    />
                    {getFormErrorMessage('confirm_password', formik)}
                </div>
            </> 
        );
    }

    const renderRequiredAttributesUI = () => {
        const convertToPlaceholder = (str) => {
            if(AwsAttributes.hasOwnProperty(str)){
                return AwsAttributes[str];
            }
            else
                return str
                    .split('_')
                    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
                    .join(' ');
        }
        return (
            <>
                <Divider type="solid" />
                <div className='pasword-reset-form-field  password-field'>
                    <span className="f-size-11">Please provide the below required attribute(s) to change your password.</span>
                </div>
                {
                    requiredAttributes.map(attribute => (
                        <div className='pasword-reset-form-field  password-field'>
                            <InputText
                                placeholder={convertToPlaceholder(attribute)}
                                key={attribute}
                                name={attribute}
                                type="text"
                                className={classNames('email-input desktop-input')}
                                onChange={(e) => { requiredAttributes[attribute] = e.target.value; }}
                                data-testid='phone_number_input'
                            />
                            {attribute === TEXT.PHONE_NUMBER && (<span className="f-size-11">{TEXT.PH_NUMB_FORMAT_MSG}</span>)}
                        </div>
                    ))
                }
            </>
        );
            
    }

    return (
        <div className='custom-auth-container'>
            <div className="card flex justify-content-left form-div">
                <form className="flex flex-column gap-2" onSubmit={formik.handleSubmit} data-testid='submit-form'>
                    {renderNewPasswordUI()}                    
                    {requiredAttributes && requiredAttributes.length > 0 && renderRequiredAttributesUI()}                    
                    <Button type="submit" label={TEXT.SUBMIT} className="signon-btn lgn-pg-btn" loading={loading} data-testid='submit_button'/>
                </form>
            </div>
        </div>
    );
}

export default NewPasswordChallengeUI;