import { useFormik } from 'formik';
import React, { useState,useRef } from "react";
import './forgot_password.css'
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { TEXT,isFormFieldInvalid, getFormErrorMessage,sendForgotPasswordInvite } from './ForgotPassworUtils'
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { validateEmail } from '../../utils/Utils';
import PropTypes from 'prop-types';
function ForgotPasswordUI(props) {    
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();

    const passwordResetForm = {
        email: props.email ? props.email : ''
    }

    const passwordResetFormValidation = values => {
        const errors = {};
        validateEmail(errors, values.email)
        return errors;
    }

    const submitPasswordReset = async (data) => {
        sendForgotPasswordInvite(data).then((response) => {
            if (response.status === 200) {
                navigate('/login?rpw=true')
            } else {                
                displayToastMessage(TEXT.ERROR, response?.message)
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    const formik = useFormik({
        initialValues: passwordResetForm,
        validate: passwordResetFormValidation,
        onSubmit: async (data) => {
            setLoading(true)
            data && submitPasswordReset(data)
        }
    });

    const displayToastMessage = (severity, message) => {
        let errorClass= severity === TEXT.ERROR ? TEXT.FAILURE_TOAST : TEXT.SUCCESS_TOAST;
        let toastMessage = {
            severity: severity,
            life: 3000,
            summary : message,
            className : errorClass
        }
        toast.current.show(toastMessage);
    };
  
    return(
        <div className='forgot_password_container'>
            <Toast ref={toast} position='top-center' />
            <div className='white-header'>
                <div className="top home-top header-image" >
                    <div className='col c4 menu-logo-container' >
                        <a href='https://www.coverahealth.com/'>
                            <img alt='Covera' data-src='img/logo-light.png'
                                className='logo Ls-is-cached lazyloaded'
                                src='img/logo-light.png'
                            />
                        </a>
                    </div>
                    <div className="ui grid middle aligned form-container">
                        <div className="row">
                            <div className="column">
                                <h2 className='forgot-password-txt'>{props.header}</h2>
                                <span>{props.instructions}</span>
                                <form className="flex flex-column gap-2" onSubmit={formik.handleSubmit} data-testid='submit-btn'>
                                    <div className='pasword-reset-form-field'>
                                        <InputText
                                            id="email"
                                            name="email"
                                            type = "email"
                                            placeholder={TEXT.EMAIL}
                                            value={formik.values.email}
                                            onChange={(e) => {
                                                formik.setFieldValue('email', e.target.value);
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('email', formik) }, 'email-input desktop-input reset-password-imput')}
                                            data-testid='email-input'
                                        />
                                        {getFormErrorMessage('email', formik)}
                                    </div>
                                    <Button type="submit" label={TEXT.SUBMIT} className="signon-btn lgn-pg-btn reset_submit_form" loading={loading} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* For Mobile version */}
                <div className='mobile-text' style={{ color: '#005c62' }}>
                    <div className='col cl2 mobile-header'>
                    </div>
                </div>
            </div>
        </div>
    )
}

ForgotPasswordUI.propTypes = {
    email: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    instructions: PropTypes.string.isRequired
};
function ForgotPassword(){
    return <ForgotPasswordUI email="" header={TEXT.FORGOT_PASSWORD} instructions={TEXT.FORGOT_PASSWORD_INSTRUCTION} />
}
function ExpirePassword(){
    const {state} = useLocation();
    return <ForgotPasswordUI email={state?state.email:''} header={TEXT.EXPIRE_PASSWORD} instructions={TEXT.EXPIRE_PASSWORD_INSTRUCTION} />
}
export {ForgotPassword, ExpirePassword};
