import { API_ENDPOINTS } from "../../utils/UrlUtils"
import { invokePostApiWithoutAuthHeader } from "../../utils/api"

export const TEXT =  {
    EXPIRE_PASSWORD: 'Reset your password.',
    EXPIRE_PASSWORD_INSTRUCTION: 'For data security purposes, you are required to change your password every 90 days. Please enter your email address and we will send password reset instructions.',
    FORGOT_PASSWORD: 'Forgot your Password?',
    FORGOT_PASSWORD_INSTRUCTION: 'Please enter your email address and we will send password reset instructions.',
    EMAIL: 'email address',
    SUBMIT: 'Submit',
    EMAIL_REQUIRED: 'Email is required.',
    INVITATION_SUCCESS_MESSAGE: 'If your account is valid, you will receive an email with password reset instructions. Please allow a few minutes while the email is in transit.',
    SUCCESS_TOAST: 'success-toast',
    FAILURE_TOAST: 'error-toast',
    ERROR: 'ERROR'    
}

export const isFormFieldInvalid = (name, formik) => {return !!(formik.touched[name] && formik.errors[name])}
export const getFormErrorMessage = (name, formik) => {
    return isFormFieldInvalid(name, formik) ? <p className="p-error">{formik.errors[name]}</p> : '';
};

export const sendForgotPasswordInvite = async (data) =>{
    return await invokePostApiWithoutAuthHeader(API_ENDPOINTS.RESET_PWD_INVITATION, data, true) //simplePostWithoutAuthentication(url,data)
}
