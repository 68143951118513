import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getExamStatusFilter } from "../../utils/Utils";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment'

export default function ActionComponent(props) {

    const examStatuses = getExamStatusFilter(false, props?.apiConfig?.isAmbra);
    const [examStatus, setExamStatus] = useState(null)
    const [followupDate, setFollowupDate] = useState(null)

    const formValidation = values => {
        const errors = {};
        if(!values?.selectedExams || values?.selectedExams.length < 1){
            errors.selectedExams = 'Required';
        }
        else if (!values?.exam_status && !values?.followup_date) {
            errors.exam_status = 'Required';
            errors.followup_date = 'Required';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            selectedExams: props.selectedExams,
            exam_status: '',
            followup_date: null,
            closeOnSuccess: true
        },
        validate: formValidation,
        onSubmit: (data) => { 
            data && props.submitAuthInfo(data);
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const setErrorElement = (msg) => {
        return  <p className="error-text">{msg}</p> 
    }

    const handleDateChange = (e) =>{  
        setFollowupDate(e.value)
        formik.setFieldValue('followup_date', moment(e.value).format('YYYY-MM-DD')) 
    }

    const customItemSelectdTemplate = () => {
        return (<div className="exam-update-select">
            {formik.values?.selectedExams?.length} exams selected
        </div>)
    }

    return (
        <div className="card flex justify-content-center mt-20">
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2" data-testid='submit-form'>
                <div className="row row-pb">
                    <span className="p-float-label">
                        <MultiSelect 
                            id="selected_referral"
                            value={formik.values?.selectedExams} 
                            filter
                            onChange={(e) => {
                                    props.setSelectedExams(e.value);
                                    formik.setFieldValue('selectedExams', e.value);
                                    props.setDisableAction(e.value.length === 0);
                                }
                            } 
                            options={props.referral}
                            optionLabel="Patient Name" 
                            maxSelectedLabels={0} className="w-full md:w-20rem"
                            display="chip" 
                            placeholder="Please select exam(s)"
                            selectedItemsLabel="{0} exams selected"
                            selectedItemTemplate = {customItemSelectdTemplate}
                            resetFilterOnHide = {true}
                            data-testid="patient-name-dropdown"
                        />
                        <label htmlFor="selected_referral">Selected exams</label>
                    </span>
                </div>
                <div className="row row-pb status-dropdown-btn">
                    <Dropdown
                        inputId="exam_status_dropdown"
                        value={examStatus} options={examStatuses} optionLabel="displayText"
                        optionValue="value"
                        onChange={(e) => {
                            setExamStatus(e.value)
                            formik.setFieldValue('exam_status', e.value)
                        }}
                        placeholder="Please select status"
                        data-testid='status-dropdown'
                    />
                </div>
                <div className="row row-pb followup-input">
                    <Calendar value={followupDate}
                        onChange={handleDateChange}
                        showIcon placeholder="Follow-up Date"
                        data-testid='calender-followup'
                    //    minDate={new Date}
                    />
                </div>
                <div className="row form-submit">
                    <Button
                        className='p-button-custom form-submit-btn' type="submit" label="Apply Updates" data-testid='apply-update'
                    />
                    {
                        isFormFieldInvalid('selectedExams') ? setErrorElement('Please select exam(s)!') :
                        isFormFieldInvalid('exam_status') && isFormFieldInvalid('followup_date') && setErrorElement('Please provide exam status or follow-up date!')
                    }
                    
                </div>                
            </form>
        </div>
    );
}
ActionComponent.propTypes = {
    selectedExams: PropTypes.object.isRequired,
    submitAuthInfo: PropTypes.func.isRequired,
    setSelectedExams:PropTypes.func.isRequired,
    setDisableAction:PropTypes.func.isRequired,
    referral: PropTypes.object,
};
