//Labels and constant values used in this component.
export const TEXT =  { 
    EMAIL_REQUIRED: 'Email is required.',
    PASSWORD_REQUIRED: 'Password is required.',
    VERIFICATION_CODE_REQUIRED: 'Verification code is required.',
    CURRENT_PASSWORD_REQUIRED: 'Current password is required.',
    NEW_PASSWORD_REQUIRED: 'New password is required.',
    PASSWORD_LENGTH_VALIDATION_MESSAGE : "Password must have at least 10 characters",
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required.',
    CONFIRM_PASSWORD_UNIQUE: 'Confirm password is not matched with new password.',
    CURRENT_NEW_PWD_NOT_SAME : 'New Password cannot match Current Password.',
    VERIFICATION_CODE_NOT_VALID_LENGTH: 'Verification code should be 6 digit',
    ENTER_MFA_CODE_TEXT: 'Please enter the 6-digit code we sent to your email address:',
    MFA_RESEND_SUCCESS: 'Verification code resent successfully',
    MFA_RESEND_CODE: 'resend code',
    MFA_REQUEST_HELP: 'request help',
    TITLE: 'Covera Provider Portal',
    SIGN_IN: 'Sign in',
    SUBMIT: 'Submit',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    Current_OR_TEMP_PASWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    INVALID_VERIFICATION_CODE: 'Verification code is incorrect. Please try again.',
    FORM_HEADER_TEXT: 'Covera Provider Portal',
    ERROR_CLASS_NAME: 'error-msg',
    SUCCESS_CLASS_NAME: 'success-msg',
    PHONE_NUMBER : 'phone_number',
    PH_NUMB_FORMAT_MSG:"Enter a phone number, including + and the country code, for example +12065551212.",
    INVALID_CURRENT_PASSWORD: "Invalid current password",
    CHANGE_PASSWORD_ERROR : 'Password reset failed. Please try again and re-confirm that the password requirements have been met.',
    FILL_OUT_REQ_ATTRIBUTES : 'Please fill out all required attributes.',
    CHANGE_PASSWORD_LABEL : 'Please change your password to continue:',
    INVALID_USERNAME_OR_PASSWORD: 'Email address or password is invalid.',
    NOT_AUTHORIZED_ERROR: ['User does not exist.', 'Incorrect username or password.', 'User is disabled.']
};

//Components available in the CustomAuthFlow
export const UI_COMPONENT = {
    AUTH_CHALLENGE: "AUTH_CHALLENGE", //For username and password screen.
    VERIFY_CHALLENGE: "VERIFY_MFA_CHALLENGE", //For MFA screen.
    NEW_PASSWORD_CHALLENGE: "NEW_PASSWORD_REQUIRED" //For MFA screen.
    //FORGOT_PASSWORD: "FORGOT_PASSWORD", For future implementations.    
}

/*
To control the form validation through out all the components.
*/
export const isFormFieldInvalid = (name, formik) => !!(formik.touched[name] && formik.errors[name]);
export const getFormErrorMessage = (name, formik) => {
    return isFormFieldInvalid(name, formik) ? <p className="p-error">{formik.errors[name]}</p> : '';
};

/*
Alert message defined as a Component.
Declaring the initial state for alert message.
*/
export const initAlertState = {
    alertType: '', alertMessage: ''
}

//To clear the alert message.
export const clearAlert = (props) => {
    props.setAlertState(initAlertState);
}

/*
Various component accessing the alert message.
Props - can be passed as a parameter to update the alert message.
formStyle - whether it Success or Error class names.
msg - Actual message to be displayed.
*/
export const setAlertMessage = (props, formStyle, msg) => {
    let alertState = {
        alertType: formStyle,
        alertMessage: msg
    }
    props.setAlertState(alertState);
}

/**
 * Get the standard auth error message
 * @param {*} msg - auth error message
 * @returns the message
 */
export const getAuthErrorMessage = (msg) => {
  if (TEXT.NOT_AUTHORIZED_ERROR.includes(msg)) {
    return TEXT.INVALID_USERNAME_OR_PASSWORD;
  } else {
    return msg;
  }
};

/**
 * AWS user attributes to display the placeholder in UI 
 */
export const AwsAttributes = {
  given_name: "First Name",
  family_name: "Last Name",
  phone_number: "Phone Number",
};