import React, { useEffect, useRef, createRef } from "react";

function PinInputView(props){    
    const {mfaInput, setMFAInput, maxLength} = props;
    const inputRefs = useRef([]);

    useEffect(() =>{
        inputRefs.current = Array(maxLength).fill(0).map((_,i) => inputRefs.current[i] || createRef());
    },[maxLength])

    const handlePinEntered = (index, event) =>{
        const value = event.key;
        const newMFA  = [...mfaInput];
        newMFA[index] = value;
        setMFAInput(newMFA);
        if(index<maxLength){
            inputRefs.current[index].value = value;
            inputRefs.current[index].value = '';
            if(index < maxLength -1){
                inputRefs.current[index + 1].focus();
            }
        }
        props.handleMFAEntered(newMFA.join(''))
        
    }

    const handleBackspaceEntered = (index) =>{
        const newMFA = [...mfaInput];
        if(newMFA[index] === ''){
            newMFA[index - 1] = '';
            setMFAInput(newMFA);
            if(index > 0){
                inputRefs.current[index - 1].focus();
            }
        }else{
            newMFA[index] = '';
            setMFAInput(newMFA);
            inputRefs.current[index].focus();
        }
        props.handleMFAEntered(newMFA.join(""))
    }

    const handleKeyPressed = (index, event) => {
        
        if(event.key === 'Backspace' && index >= 0){
            handleBackspaceEntered(index)
           
        }else if(event.key === 'ArrowRight' && index < maxLength-1){
            inputRefs.current[index +1 ].focus();

        }else if(event.key === 'ArrowLeft' && index > 0){
            inputRefs.current[index - 1].focus();
        }else if(/^[a-zA-Z0-9]$/.test(event.key)){
            handlePinEntered(index, event)
        }
    }
    
    const handlePaste = (event) => {
        event.preventDefault();
        const pastedOtp = event.clipboardData.getData('text');
        const newMFA = [...mfaInput];
        for(let i = 0 ; i < newMFA.length; i++){
            if(pastedOtp[i]){
                newMFA[i] = pastedOtp[i];
            }
        }
        setMFAInput(newMFA.join(""));
        props.handleMFAEntered(newMFA.join(""))

    }

    return <div className={!props.MFAvalid? "pin-input-parent-error":"pin-input-parent"} onPaste={handlePaste}>
        {Array.from({length : maxLength},(_,index) =>(
        <input className="pin-input" key={index} title="pin-input" 
            type = "tel"
            maxLength="1"
            value={mfaInput[index]}
            onKeyDown={(e) => handleKeyPressed(index,e)}
            ref={(ele) => {inputRefs.current[index] = ele}}
        />
    ))}
    </div>   
}

export default PinInputView;