import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

function Footer(props) {
    const navigate = useNavigate();
    
    const updateSelectedTab = (e)=>{
        const {setSelectedTab, user} = props;
        if(setSelectedTab && user){
            props?.setSelectedTab('help')
        }else{
           navigate("/help") 
        }
    }
    return (

        <footer>
            <div className="ui sixteen column grid center aligned">
                <div className="border-right-3"><a className="item" href="https://www.coverahealth.com/">Covera Health © 2023</a> </div>
                <div className="border-right-3">
                    <a className="item" href="https://www.coverahealth.com/privacy-policy.html">Privacy Policy&nbsp;/&nbsp;</a>
                    <a className="item" href="https://www.coverahealth.com/termsconditions.html">Terms &amp; Conditions</a>
                </div>
                <div className="help-txt"><button className="item help-txt" onClick={updateSelectedTab} data-testid='help-text'>Help</button> </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    setSelectedTab: PropTypes.func,
    user: PropTypes.string
}

export default Footer;

