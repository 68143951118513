import AWS from 'aws-sdk'
import { API_ENDPOINTS, API_PREFIX } from '../../../utils/UrlUtils'
import { ReferralService } from '../ReferralService'
import { getAuthCsrfToken } from "../../../utils/api";


export const UploadStatusEnum = {
  QUEUED: "QUEUED",
  STARTED: "STARTED",
  INPROGRESS: "INPROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PAUSED: "PAUSED",
  PAUSING: "PAUSING",
  NONE : ""
}

export const evaporateConfig = {
  signerUrl: null,
  aws_key: null,
  awsRegion: null,
  bucket: null,
  signHeaders : null,
  cloudFront: true,
  computeContentMd5: true,
  cryptoMd5Method: (data) => { return AWS.util.crypto.md5(data, 'base64'); },
  cryptoHexEncodedHash256: (data) => { return AWS.util.crypto.sha256(data, 'hex'); },
  logging: false,
  s3FileCacheHoursAgo: 1,
  allowS3ExistenceOptimization: true
}

export const getEvaporateConfig = async(apiConfig) => {
  let configVal = {};
  const signHeaders = await getAuthCsrfToken()

  await ReferralService.getS3Config().then((data) => {
    if(data){
        configVal ={
            aws_key: data['aws_key'],
            bucket: data['aws_s3_bucket_name'],
            signerUrl: API_PREFIX.VERSION + '/' + apiConfig.customer + API_ENDPOINTS.SIGN_AUTH_V4,
            awsRegion: data['aws_region'],
            signHeaders : signHeaders
        }
        if (apiConfig?.max_retry_backoff_secs)
          configVal['maxRetryBackoffSecs'] = apiConfig?.max_retry_backoff_secs
        if (apiConfig?.max_retry_backoff_power)
          configVal['retryBackoffPower'] = apiConfig?.max_retry_backoff_power
        configVal = {...evaporateConfig, ...configVal }
    }        
  });
  return configVal;
}


export const ZIP_MIME_TYPES = [
  "application/x-zip", "application/x-zip-compressed", "application/octet-stream",
  "application/x-compress", "application/x-compressed", "multipart/x-zip", "application/zip"
]

export const PDF_MIME_TYPES = [
  "application/pdf"
]
