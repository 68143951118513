/**
 * Returns the exam status
 * @param {*} all - Specifies the all status visibility
 * @returns the exam status array
 */
export function getExamStatus(all, isAmbra) {
    return [
        {
            value: "NEW",
            displayText: "New",
            visible: true
        },
        {
            value: "PENDING",
            displayText: "Pending",
            visible: true
        },
        {
            value: "EXAM_SCHEDULED",
            displayText: "Exam Scheduled",
            visible: true 
        },
        {
            value: "EXAM_COMPLETE",
            displayText: "Exam Complete",
            visible: true
        },
        {
            value: "COLL_COMPLETE",
            displayText: "Collection Complete",
            visible: isAmbra
        },
        {
            value: "EXAM_CANCELED",
            displayText: "Exam Canceled",
            visible: true
        }
    ];
}

export function getModality() {
    return [
        {
            value: "CT",
        },
        {
            value: "MRI"
        },
        {
            value: "PET",     
        }
    ];
}

var entityMap = {
    '&': '&',
    '<': '<',
    '>': '>',
    '"': '"',
    "'": '\'',
    '/': '/',
    '`': '`',
    '=': '='
};

const TEXT =  {
    EMAIL_REQUIRED: 'Email is required.',
    INVALID_EMAIL_ADDRESS : 'Invalid Email address'
}

export const APP_TITLE = 'Covera Health Provider Portal'

export function escapeHtml(string) {
    return String(string).replace(/[&<>"'`=/]/g, function (s) {
        return entityMap[s];
});
}

export function getUserActionLabel() {
    return {
        ADD: "Add New User",
        EDIT: "Edit User"
    };
}

export const ReferralActions =  { 
    UpdateExam: "Update Exams",
    StudyUpload: "Upload Study"
};

export function getExamStatusFilter(showAll, isAmbra) {
    return getExamStatus(showAll, isAmbra).filter((item) => item.visible);
};

export function validateEmail(errors,email){
    if (!email || email.trim() === '') {
        errors.email = TEXT.EMAIL_REQUIRED;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = TEXT.INVALID_EMAIL_ADDRESS;
    }
}

export function getFormattedEmail(email) {
  if (email) {
    return email.toLowerCase();
  }

  return "";
}

export const compareArray = (arr1, arr2) => {
    
    if(arr1.length !== arr2.length){
        return true;
    }

    const sortedArr1 = arr1.slice().sort((a,b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    const sortedArr2 = arr2.slice().sort((a,b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

    for(let i=0;i<sortedArr1.length;i++){
        if(JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])){
            return true;
        }
    }
    return false;

}


export const STUDY_UPLOAD_REPORT_TYPE = {
    DICOM : "DICOM", 
    REPORT: "REPORT",
    ZIP_EXTENSION: ".zip",
    PDF_EXTENSION: ".pdf"
}

export const STUDY_UPLOAD_STATUS = {
    STARTED : "STARTED",
    INPROGRESS : "INPROGRESS",
    COMPLETED : "COMPLETED",    
    FAILED : "FAILED",
    PAUSED : "PAUSED"
}


export function setCookie(cname, cvalue, domain) {
    const enc_cvalue = btoa(cvalue)
    const d = new Date();
    // 1 minute
    d.setTime(d.getTime() + (60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + enc_cvalue + ";" + expires + ';domain='+domain+';';
}

export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}