import React, { useRef, useState } from "react";
import { useFormik } from 'formik';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { ManageUsersService } from "./service/ManageUserService";
import { getUserActionLabel, validateEmail, getFormattedEmail } from '../../utils/Utils'
import PropTypes from 'prop-types';

export default function ActionForm(props) { 
    const toast = useRef(null);
    const userActionLabel = getUserActionLabel();
    const isEditMode = props.actionType !== userActionLabel.ADD;
    const pwdResetClass = { enable: "p-button-custom", disable: "p-disabled p-button-custom-disabled" };
    const [pwdResetClassName, setPwdResetClassName] = useState(props.user.enabled ? pwdResetClass.enable : pwdResetClass.disable);
    const statusDropdownClassName = isEditMode ? 'p-input-cust-dropdown' : 'p-input-cust';
    const [loading, setLoading] = useState(false);
    const [passwordResetInProgress, setPasswordResetInProgress] = useState(false);
    const canResetPassword = props.user.userstatus !== 'FORCE_CHANGE_PASSWORD' 
    const passwordResetText = canResetPassword ? "Password Reset" : "Resend Invitation" 

    const setErrorElement = <p className="error-text">Please re-confirm highlighted fields.</p>;

    const submitData = async (data) => {
        ManageUsersService.addOrUpdateUserData(data, props.actionType).then((response) => {
            setLoading(false);
            if (response) {
                reloadUsers()
                props.setToast('success', 'Changes saved successfully!');
            } else {
                let errorMessage = 'User cannot be created. Please contact support@coverahealth.com for assistance';
                props.setToast('error', errorMessage);
            }
        });
    };

    const reloadUsers = () => {
        props.setFetching(true)
        ManageUsersService.getUsers().then((data) => {
            props.setUserList(data["usersList"]);
            props.setActionDialog(false);
            props.setFetching(false)
        });
    }


    const formValidation = values => {
        const errors = {};
        if (!values.given_name || values.given_name.trim() === '') {
            errors.given_name = 'Required';
        }
        if (!values.family_name || values.family_name.trim() === '') {
            errors.family_name = 'Required';
        }

        if (values.role === '') {
            errors.role = 'Required';
        }

        validateEmail(errors, values.email)
        
        return errors;
    };

    const formik = useFormik({
        initialValues: props.user,
        validate: formValidation,
        onSubmit: (data) => {
            setLoading(true);
            const trimedData = {};

            // Trim the empty string
            Object.entries(data).forEach(item => {
                if (item[0] === 'given_name' || item[0] === 'family_name') {
                    trimedData[item[0]] = item[1].trim();
                } else {
                    trimedData[item[0]] = item[1];
                }
            })
            trimedData && submitData(trimedData);
            //formik.resetForm();
        }
    });

    const options = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }
    ];

    const roleOptions = [
        { label: 'Administrator', value: true},
        { label: 'Contributor', value: false}
    ];

const handleRoleChange =(e) => {
        
        if(e.target.value) {
            formik.values.role = 'Administrator';
            formik.values.admin = true 
        }
        else{
            formik.values.role = 'Contributor'
            formik.values.admin = false  
        } 
        formik.setFieldValue('admin', e.target.value);
    }

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const isFormFieldsInvalid = ()=>{
        return (isFormFieldInvalid('given_name') || isFormFieldInvalid('family_name') || isFormFieldInvalid('email')  || isFormFieldInvalid('enabled'))
    }

    const displayPopup =(response) =>{
        let update_msg= ''
        if(response){
            update_msg = (<div>
                <p>Failed to reset the password!.</p>
                <p>{response['message']}</p>
            </div>)
        }
        else{
            update_msg = (<div>
                <p>Failed to reset the password!.</p>
            </div>)
        }
        return update_msg
    }

    const resetPassword = () =>{
        ManageUsersService.resetPassword(formik.values.email).then((response) => {
            let update_msg = '';
            let severity = 'success'
            if (response) {
                update_msg = "Password reset instructions have been sent to the user's email address."
            }else{
                update_msg = displayPopup('Failed to send password reset instructions!')
                severity = 'error'
            }
            props.setToast(severity, update_msg);
        }).finally(()=>{
            setPasswordResetInProgress(false);
        });
    }

    const sendUserInvitation = () =>{
        ManageUsersService.resendInvitation(props.user).then((response) => {
            let update_msg = '';
            let severity = 'success'
            if (response) {
                update_msg = "Password reset instructions have been sent to the user's email address."
            }else{
                update_msg = displayPopup('Failed to send password reset instructions!')
                severity = 'error'
            }
            props.setToast(severity, update_msg);
        }).finally(()=>{
            setPasswordResetInProgress(false);
        });
    }

    const onPasswordResetClick = (e)=>{
        setPasswordResetInProgress(true);
        if(canResetPassword){
            resetPassword() 
        }else{
            sendUserInvitation()
        }
    } 
    
    const getLabel = (loading)=>{
        if (props.actionType === userActionLabel.ADD){
            return loading ? 'Creating...' : 'Submit'
        }
        return loading? 'Updating...' : 'Save' ;
    }
    let disabled = false;
    let icon = '';
    if(loading){
        disabled = true;
        icon = 'pi pi-spin pi-spinner';
    }
    let label = getLabel(loading) 
    return (
        <div className="card flex justify-content-left form-div mt-20">
            {props.actionType === userActionLabel.ADD && <p className="add-user-info">Please fill out all of the fields.</p>}
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2"  data-testid='submit-form'>
                <span className="p-float-label p-mb-1">
                    <InputText
                        id="given_name"
                        name="given_name"
                        value={formik.values.given_name}
                        keyfilter={/^[a-z\d_\s]+$/i} /*alpha-numeric including underscore*/
                        onChange={(e) => {
                            formik.setFieldValue('given_name', e.target.value);
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('given_name') }, 'p-input-cust')}
                        data-testid='given_name_input'
                    />
                    <label htmlFor="input_value">First Name</label>
                </span>
                <span className="p-float-label p-mb-1">
                    <Toast ref={toast} />
                    <InputText
                        id="family_name"
                        name="family_name"
                        value={formik.values.family_name}
                        keyfilter={/^[a-z\d_\s]+$/i} /*alpha-numeric including underscore*/
                        onChange={(e) => {
                            formik.setFieldValue('family_name', e.target.value);
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('family_name') }, 'p-input-cust')}
                        data-testid='family_name_input'
                    />
                    <label htmlFor="input_value">Last Name</label>
                </span>
                <span className="p-float-label p-mb-1">
                    <Toast ref={toast} />
                    <InputText
                        disabled={isEditMode}
                        id="email"
                        name="email"
                        type="email"
                        value={getFormattedEmail(formik.values.email)}
                        style={isEditMode && { backgroundColor: "#e1e1e1" }}
                        onChange={(e) => {
                            formik.setFieldValue('email', getFormattedEmail(e.target.value));
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('email') }, 'p-input-cust')}
                        data-testid='email_input'
                    />
                    <label htmlFor="input_value">Email Address</label>
                </span>
                <span className="p-float-label p-mb-1">
                    <Toast ref={toast} />
                    <Dropdown
                            id="role"
                            name="role"
                            options={roleOptions}
                            value={formik.values.role !== '' ? formik.values.admin : null }
                            onChange={handleRoleChange}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('role') }, 'p-input-cust')}
                            data-testid='role-dropdown'
                        />
                        <label htmlFor="input_value dropdown-label">Role</label>
                </span>
                <span className="p-float-label p-mb-5">
                    <Toast ref={toast} />
                    <div className="form-submit">
                        <Dropdown
                            id="enabled"
                            name="enabled"
                            options={options}
                            value={formik.values.enabled}
                            onChange={(e) => {
                                formik.setFieldValue('enabled', e.target.value);
                                setPwdResetClassName(e.target.value ? pwdResetClass.enable : pwdResetClass.disable)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('enabled') }, statusDropdownClassName)}
                        />
                        <label htmlFor="input_value dropdown-label">Status</label>
                        {
                            props.actionType === userActionLabel.EDIT &&
                            <Button type="button" className={"password-reset-btn " + pwdResetClassName}  disabled={passwordResetInProgress} 
                                icon={passwordResetInProgress ? 'pi pi-spin pi-spinner' : ''}
                                label={passwordResetText}
                                onClick={onPasswordResetClick} data-testid='resend-invite-btn'
                            />
                        }
                    </div>
                </span>

                <div className="form-submit">
                    <Button className='p-button-custom form-submit-btn' disabled={disabled} icon={icon} type="submit" label={label}/>
                    {isFormFieldsInvalid() ? setErrorElement : ''}
                </div>

            </form>
        </div>
    )
}
ActionForm.propTypes = {
    setToast: PropTypes.func.isRequired,    
    user: PropTypes.object,
    actionType: PropTypes.string.isRequired,
    setFetching: PropTypes.func.isRequired,
    setUserList: PropTypes.func.isRequired,
    setActionDialog: PropTypes.func.isRequired,
};
