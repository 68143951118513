import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import Footer from '../Footer';
import ActionsForm from './ActionsForm';
import React, { useEffect, useRef, useState } from 'react';
import { getUserActionLabel, getFormattedEmail } from '../../utils/Utils'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ManageUsersService } from './service/ManageUserService';
import { Skeleton } from 'primereact/skeleton';
import './manage_users.css'

import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import PropTypes from 'prop-types';
function UserList(props) {
    let emptyUser = {
        family_name: '',
        given_name: '',
        admin: false,
        email: '',
        enabled: true,
        role: "Contributor",
        status: '',
        dateJoined: null
    };

    const [usersList, setUsersList] = useState(Array.from({ length: 1 }));
    const [actionDialog, setActionDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [actionType, setActionType] = useState(null);
    const [actionTitle, setActionTitle] = useState(null);
    const userActionLabel = getUserActionLabel();
    const [tableHeight, setTableHeight] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState(null);
    const apiConfig = props.apiConfig;

    useEffect(() => {
        setFetching(true);
        try{
            ManageUsersService.getUsers().then((data) => {
                setUsersList(data["usersList"])
            }).catch((err) => {
                setError(true)
            }).finally(() => {
                setFetching(false);
                calcTableHeight();
            });
        }catch(err){
            setError(true)
        }
    }, [apiConfig])

    const addNew = () => {
        setActionType(userActionLabel.ADD);
        setActionTitle(userActionLabel.ADD);
        setUser(emptyUser);
        setActionDialog(true);
    };

    const hideDialog = () => {
        setActionDialog(false);
    };

    const editUser = (user) => {
        setActionType(userActionLabel.EDIT);
        setActionTitle(userActionLabel.EDIT + ' - ' + user.given_name + ' ' + user.family_name);
        setUser({ ...user });
        setActionDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <h1 className="m-0 fw-400 h1-b">Manage Users</h1>
                <span className="fw-400">Manage the users for your practice's Provider Portal.</span>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label={userActionLabel.ADD} className='p-button-add' icon="pi pi-plus" data-testid="add_user" onClick={addNew} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return !fetching ? (
                    <Button title={userActionLabel.EDIT}  icon="pi pi-pencil" outlined className="mr-2 edit-user-btn" data-testid="edit-user" onClick={() => editUser(rowData)} />
            ) :  (<React.Fragment> </React.Fragment>);
    };

    window.addEventListener('resize', () => {
        calcTableHeight()
   });

    const calcTableHeight = () => {
        if (window.getComputedStyle(document.body).overflow === 'hidden') {
            const manageUsersBody = document.getElementById('manage-content-div');
            if(manageUsersBody){
                const tableHeightCalc = window.innerHeight - manageUsersBody.offsetHeight * 3;
                setTableHeight(tableHeightCalc + 'px')
            }
            
        } else {
            setTableHeight("")
        }
    }

    const displayToastMessage = (severity, message) => {
        let errorClass= severity === 'error' ? 'error-tost' : 'success-toast';
        let toastMessage = {
            severity: severity,
            life: 3000,
            summary : message,
            className : errorClass
        }
        toast.current.show(toastMessage);
    };

    const loadingTemplate = (options, colm) => { 
        return fetching ? (<Skeleton></Skeleton>) : options ? options[colm.field] : '';
    }

    const emailLoadingTemplate = (options, colm) => { 
        return fetching ? (<Skeleton></Skeleton>) : options ? getFormattedEmail(options[colm.field]) : '';
    }

    return (
        <div>
            <Toast ref={toast} position='top-center' />
            <div id="content" className="card">
                <Toolbar id="manage-content-div" className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="mt-10 pl-0">
                {error && <p className='p-error'>There was an error processing your request. Please refresh the page and try again later.</p>}
                    <DataTable ref={dt} value={usersList} //selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={25} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        //loading={fetching}
                        //currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" 
                        globalFilter={globalFilter}
                        sortField="family_name" sortOrder={1}
                        scrollable scrollHeight={tableHeight} rowClassName="row-height-custom">
                        <Column field="family_name" header="Last Name" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column field="given_name" header="First Name" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column field="email" header="Email Address" alignHeader={'center'} sortable className='manage-user-col' body={emailLoadingTemplate}></Column>
                        <Column field='role' header="Role" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column field='status_text' header="Status" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column field="date_joined" dataType="date" sortField='formatted_date_joined' header="Date Joined" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column field="custom:user_login_date" dataType="date" sortField='formatted_user_login_date' header="Date of Last Login" alignHeader={'center'} sortable className='manage-user-col' body={loadingTemplate}></Column>
                        <Column header="Actions" alignHeader={'center'} body={actionBodyTemplate} exportable={false} className='action-col'></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={actionDialog} header={actionTitle} modal className="p-fluid dialog-div" onHide={hideDialog}>
                <ActionsForm user={user} setUserList={setUsersList}
                    actionType={actionType} apiConfig={props.apiConfig}
                    setActionDialog={setActionDialog}
                    setToast={displayToastMessage}
                    toast = {toast}
                    setFetching ={setFetching}
                    >
                </ActionsForm>
            </Dialog>
            <div id="app-footer">
                <Footer user ={props.user}  setSelectedTab={props.setSelectedTab}/>
            </div>
        </div>
    )
}
UserList.propTypes = {
    apiConfig: PropTypes.object.isRequired,
};
export default UserList;