import React, { useEffect } from 'react';
import { useUploadQueueContext } from './UploadQueueContextApi';
import { UploadStatusEnum, ZIP_MIME_TYPES, PDF_MIME_TYPES } from './UploadConfig';
import FileUploadComponent from './FileUploadComponent';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import PropTypes from 'prop-types';

const UploadForm = ({ examDetails, formState, setActionDialog, displayToastMessage }) =>{
    const { exam_id } = examDetails
    const { addToQueue, removeFile, evaporate, apiConfig, uploadContextState, auditEvents } = useUploadQueueContext();
    const currentState = uploadContextState?.queue?.find(({ id }) => id === exam_id);
    const formDisabled = currentState && currentState?.status !== UploadStatusEnum.FAILED
    const isUploading = currentState?.fileInfo?.some(res => [UploadStatusEnum.INPROGRESS, UploadStatusEnum.STARTED].includes(res?.status))
    const getCurrentFormStatus = () => {
        if (currentState?.fileInfo?.some(res => [UploadStatusEnum.INPROGRESS, UploadStatusEnum.STARTED].includes(res?.status)))
            return UploadStatusEnum.INPROGRESS
        else if (currentState?.fileInfo?.every(res => res?.status === UploadStatusEnum.QUEUED))
            return UploadStatusEnum.QUEUED
        else if (currentState?.fileInfo?.every(res => res?.status === UploadStatusEnum.COMPLETED))
            return UploadStatusEnum.COMPLETED
        else if (currentState?.fileInfo?.some(res => UploadStatusEnum.FAILED === res?.status))
            return UploadStatusEnum.FAILED
        else
            return UploadStatusEnum.NONE
    }
    const getSubmitButtonTemplate = () => {
        const status = getCurrentFormStatus()
        if ([UploadStatusEnum.INPROGRESS, UploadStatusEnum.STARTED, UploadStatusEnum.QUEUED].includes(status))
            return (
                <Button label="Cancel Upload" className='upload-button'
                    data-testid="upload-btn"
                    onClick={(e)=>{
                    e.preventDefault();
                    if (status ===UploadStatusEnum.QUEUED)
                        removeFile(exam_id);
                    else
                        evaporate.cancel();
                    formik.resetForm(); 
                    formik.setFieldValue('accept', false);
                }}/>
            ) 
        else if (status === UploadStatusEnum.COMPLETED)
            return <></>
       
        return (
            <div className="d-flex justify-center" title={disableUpload ? 'Select files and confirm data transmission in order to continue with study upload.' : 'Complete the study upload.'}>
                <Button loading={isUploading} disabled={disableUpload} type="submit" label="Upload Study" data-testid = "test-btn-upload"
                    className='upload-button' icon="pi pi-cloud-upload" />
            </div>
        )
    }

    const TEXT_MESSAGE = {
        DEFAULT_MESSAGE: 'Information not provided',
        ACCESSION_REQUIRED: "Accession Number is required.",
        ACCESSION_INVALID: "Please enter valid accession number",
        CONFRIM_MESSAGE: "Attestation checkbox is required. Please confirm.",
        NUMBER_OF_FILE_VALIDATION: "Only one file can be selected for each component.",
        DICOM_FILE_VALIDATION: "DICOM file must be in .zip format.",
        REPORT_FILE_VALIDATION: "Report file must be in .pdf format.",
        DICOM_UPLOAD_MESSAGE: "DICOM file is required.",
        REPORT_UPLOAD_MESSAGE: "Report file is required.",
        SELECT_FILE: 'SELECT_FILE',
        UPLOAD_FILE: 'UPLOAD_FILE',
        SUCCESS_FLAG: "SUCCESS",
    };
    const getData = (key) => {
        let value = examDetails?.[key]?.trim() ? examDetails[key] : TEXT_MESSAGE.DEFAULT_MESSAGE;
        if (key === 'DOB' && value.toString() !== TEXT_MESSAGE.DEFAULT_MESSAGE) {
            return moment.utc(value).format('MMMM DD, YYYY');
        }
        return value;
    }

    const authorizationData = [
        { title: 'Patient', value: getData('Patient Name') },
        { title: 'Date of Birth', value: getData('DOB') },
        { title: 'Authorization #', value: getData('Auth Number') },
        { title: 'Exam Description', value: getData('CPT with Description') }
    ]
    const renderExamDetails = (details) => {
        return (
            <p key={JSON.stringify(details)} className="upload-exam-details"><span className="upload-exam-details-title">{details?.title} : </span>{details?.value}</p>
        )
    }

    const initPayload = {
        id: null,
        examId: null,
        accessionNumber: null,
        fileInfo: [], // [file, reportType]
        status: UploadStatusEnum.QUEUED,
        isUploading: false,
        isCompleted: false
    }

    const initReportTypePayload = {
        file: null,
        reportType: null,
        status: UploadStatusEnum.QUEUED,
        progress: null,
        isUploading: false,
        s3_dir_name: null
    }

    const preparePayload = (data, reportType) => {
        let s3_dir_name = examDetails?.['Practice'] ? `${examDetails?.['Practice']}/` : '';
        let accession_num = formik?.values?.accessionNumber ? `${formik?.values?.accessionNumber}/` : '';
        s3_dir_name = s3_dir_name + `${examDetails?.['exam_id']}/${accession_num}${reportType}/`;
        const newPayload = { file: data, reportType: reportType, s3_dir_name: s3_dir_name }
        return { ...initReportTypePayload, ...newPayload }
    }

    const formik = useFormik({
        initialValues: formState,
        enableReinitialize: false,
        validate: (data) => {
            let errors = {};
            const regex = /^[^/]*$/;
            if (!apiConfig?.report_in_dicom && !data.accessionNumber) {
                errors.accessionNumber = TEXT_MESSAGE.ACCESSION_REQUIRED;
            }
            else if (!apiConfig?.report_in_dicom && !regex.test(data.accessionNumber)) {
                errors.accessionNumber = TEXT_MESSAGE.ACCESSION_INVALID;
            }
            if (examDetails?.['DICOM Upload Status'] !== UploadStatusEnum.COMPLETED && !data.dicom) {
                errors.dicom = TEXT_MESSAGE.DICOM_UPLOAD_MESSAGE;
            }
            else if(data.dicom && !ZIP_MIME_TYPES.includes(data.dicom?.type)){
                errors.dicom = TEXT_MESSAGE.DICOM_FILE_VALIDATION
            } 
            if (!apiConfig?.report_in_dicom && examDetails?.['Report Upload Status'] !== UploadStatusEnum.COMPLETED && !data.report) {
                errors.report = TEXT_MESSAGE.REPORT_UPLOAD_MESSAGE;
            }
            else if(!apiConfig?.report_in_dicom && examDetails?.['Report Upload Status'] !== UploadStatusEnum.COMPLETED && data.report && !PDF_MIME_TYPES.includes(data.report?.type)){
                errors.report = TEXT_MESSAGE.REPORT_FILE_VALIDATION
            }
            if (!data.accept) {
                errors.accept = TEXT_MESSAGE.CONFRIM_MESSAGE;
            }
            return errors;
        },
        onSubmit: (data) => {
            const fileInfoPayload = [
            ]
            if (data.dicom)
                fileInfoPayload.push(preparePayload(data.dicom, 'dicom'))
            if (data.report)
                fileInfoPayload.push(preparePayload(data.report, 'report'))
            const newPayload = {
                id: exam_id, examId: exam_id,
                accessionNumber: data.accessionNumber,
                fileInfo: fileInfoPayload
            }
            const payload = { ...initPayload, ...newPayload }
            addToQueue(payload);
            displayToastMessage('success', 'Study added to Upload queue for ' + examDetails?.['Patient Name'] + '!');
            setActionDialog(false);   
        }
    });

    const disableAccept = () => {
        if (formDisabled)
            return true

        const formVal = []
        if (examDetails?.['DICOM Upload Status'] !== UploadStatusEnum.COMPLETED)
            formVal.push(formik.values.dicom)
        if (!apiConfig?.report_in_dicom && examDetails?.['Report Upload Status'] !== UploadStatusEnum.COMPLETED){
            formVal.push(formik.values.report)
            formVal.push(formik.values.accessionNumber)
        }
        return !formVal.every(element => element !== null && element !== '' && element !== undefined);
    }
    const disableUpload = !(!disableAccept() && formik.values.accept)

    function fetchError() {
        for (const key in formik.touched) {
            if (formik.touched[key] && formik.errors[key]) {
                return formik.errors[key]
            }
        }
        return ''
    }
    useEffect(() => {
        formik.setValues(formState)
    }, [formState]);

    const getAsteriskElement = () => {
        const style = {
            'color': 'red',
            'font-style': 'normal'

        }
        return <i className='asterisk' style={style}>*</i>
    }

    return (
        <div>
            <div className="flex justify-content-center">
                <div className="card">
                    <p className='font-size-12'>Upload the study for the following Covera exam:</p>
                    <div className="flex flex-column gap-2">
                        <div className="ui one column grid upload-exam-content">
                            <div className="column">
                                {
                                    authorizationData?.map(details => renderExamDetails(details))
                                }
                            </div>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        {!apiConfig?.report_in_dicom &&
                            <div className="accession-number">
                                <label htmlFor="input_value">{getAsteriskElement()} Accession Number:</label>
                                <InputText
                                    id="accessionNumber"
                                    name="accessionNumber"
                                    value={formik.values.accessionNumber}
                                    disabled={formDisabled}
                                    keyfilter={/[a-z\d.-]/i}
                                    onChange={formik.handleChange}
                                    className='p-input-cust'
                                    data-testid='accession-number'
                                    maxLength={40}
                                /> 
                            </div>
                        }

                        {(examDetails?.['DICOM Upload Status'] !== UploadStatusEnum.COMPLETED || getCurrentFormStatus() == UploadStatusEnum.COMPLETED) && <div className="mt-10">
                            <div className='file-upload-div'>
                                {getAsteriskElement()}
                                <FileUploadComponent
                                    auditEvents={auditEvents}
                                    field="dicom" form={formik}
                                    trackingId={exam_id}
                                    disable={formDisabled}
                                />
                            </div>
                        </div>}
                        {((!apiConfig?.report_in_dicom && examDetails?.['Report Upload Status'] !== UploadStatusEnum.COMPLETED) || (getCurrentFormStatus() == UploadStatusEnum.COMPLETED) ) && <div className="mt-10">
                            <div className='file-upload-div'>
                                {getAsteriskElement()}
                                <FileUploadComponent
                                    auditEvents={auditEvents}
                                    field="report" form={formik}
                                    trackingId={exam_id}
                                    disable={formDisabled}
                                />
                            </div>
                        </div>}

                        <div className='mt-10'>
                            <div className='file-upload-div'>
                                {getAsteriskElement()}
                                <Checkbox disabled={disableAccept()} inputId="accept" name="accept" checked={formik.values.accept} onChange={formik.handleChange} data-testid="upload-checkbox"
                                />
                                <label htmlFor="accept"
                                    className='ml-12 font-size-12'
                                >I confirm that I am transmitting PHI for the appropriate patient and exam</label>
                            </div>
                        </div>
                        <div className="d-flex justify-center">
                            <p className='validation-msg'>{fetchError()}</p>
                        </div>
                        {
                           getSubmitButtonTemplate()
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

UploadForm.propTypes = {
    examDetails: PropTypes.any,
    formState : PropTypes.object,
    setActionDialog : PropTypes.func,
    displayToastMessage : PropTypes.any
};

export default UploadForm;