import { API_ENDPOINTS } from "../../utils/UrlUtils";
import { invokeGetApi, invokePostApi } from "../../utils/api";

export const ReferralService = {

    /* Get the referral list  */
    async getReferralList(data) {
        if(!data){
            data = {
                search : '',
                length : 25,
                start : 0,
                exam_filter : 'PENDING'
            }
        }
        return await invokePostApi(API_ENDPOINTS.REFERRALS, data)
    },

    /* Get the facility names  */
    async getFacilityNames() {
        return await invokeGetApi(API_ENDPOINTS.GET_FACILITIES)
    },

    /*Get modalities*/
    async getModalities(){
        return await invokeGetApi(API_ENDPOINTS.GET_MODALITY)
    },

    /* Update the exam status */
    async submitAuthInfo(data) {
        let requestData = {
            "exam_id_list" : data['exam_id_list']            
        };
        requestData['auth_info'] = {}
        requestData = this.prepareKeyValueParam(requestData, "exam_status", data.exam_status)
        requestData = this.prepareKeyValueParam(requestData, "followup_date", data.followup_date)       
        if(data['exam_notes'])
            requestData = this.prepareKeyValueParam(
                requestData, "exam_notes", { 'notes_type' : 'PROV. COMMENT', 'notes' : data.exam_notes } 
            )       

        return await invokePostApi(API_ENDPOINTS.UPDATE_AUTHORIZATION, requestData)
    },

    /* Audit the auth info */
    async auditAuthInfo(data) {
        let requestData = {
            "exam_id_list" : data['exam_id_list'],
            "column_info": data['column_info']
        };

        return await invokePostApi(API_ENDPOINTS.AUDIT, requestData)
    },

    prepareKeyValueParam(param, key, value){
        if(value){
            param['auth_info'][key] = value
        }
        return param
    },

    /* Get the facility names  */
    async getExamDetails(examId) {
        return await invokeGetApi(API_ENDPOINTS.GET_EXAM_DETAILS + '/' + examId.toString())
    },

    async getS3Config() {
        return await invokeGetApi(API_ENDPOINTS.GET_S3_CONFIG)
    },

    async auditUploadEvent(requestData) {
        return await invokePostApi(API_ENDPOINTS.STUDY_UPLOAD_AUDIT, requestData)
    },
    async updateStudyUploadStatus(requestData) {
        return await invokePostApi(API_ENDPOINTS.STUDY_UPLOAD_STATUS_UPDATE, requestData)
    },
    /* Get the Study UPload details by Exam ID  */
    async getStudyUploadDetails(examId) {
        return await invokeGetApi(API_ENDPOINTS.GET_STUDY_UPLOAD_DETAILS + '/' + examId.toString())
    }
    
};