import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose a theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Tag } from 'primereact/tag';
import { useUploadQueueContext } from './UploadQueueContextApi';
import { UploadStatusEnum, PDF_MIME_TYPES, ZIP_MIME_TYPES } from './UploadConfig';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import PropTypes from 'prop-types';

const FileUploadComponent = ({ field, form, trackingId, disable }) => {
    const SELECT_FILE = 'SELECT_FILE'
    const SUCCESS_FLAG = "SUCCESS"
    const { uploadContextState, evaporate, auditEvents } = useUploadQueueContext();
    const currentState = uploadContextState?.queue?.find(({ id }) => id === trackingId)
    const componentConfig = {
        'dicom': {
            mimeTypes: ZIP_MIME_TYPES,
            acceptedFiles: '.zip',
            labelText: 'Select DICOM',
            upperCase: 'DICOM',
            tooltipText: 'Select DICOM file for upload.',
            validationMessage: "DICOM file must be in .zip format.",
            dragDropText: "You can drag and drop the DICOM file here. DICOM file must be in .zip format."
        },
        'report': {
            mimeTypes: PDF_MIME_TYPES,
            acceptedFiles: '.pdf',
            labelText: 'Select Report',
            upperCase: 'REPORT',
            tooltipText: 'Select Report file for upload.',
            validationMessage: "Report file must be in .pdf format.",
            dragDropText : "You can drag and drop the Report file here. Report file must be in .pdf format."
        }
    }


    const invalidMessage = (fileExtension) => `Invalid ${componentConfig[field].upperCase} file selected (.${fileExtension}).`

    const fileValidation = (files) => {
        const allowSelect = files && files.length === 1;
        if (!allowSelect) {
            return false;
        }
        const fileType = files[0].type;
        if (!componentConfig[field].mimeTypes.includes(fileType)) {
            let ext = files[0]?.name?.split('.')?.pop()
            auditEvents(trackingId, SELECT_FILE, field, `.${ext}`, UploadStatusEnum.FAILED, invalidMessage(ext))
            form.setTouched({})
            form.setFieldTouched(field, true)
            form.setFieldValue(field, files[0])
            return false;
        }
        return true
    };

    const onBeforeSelect = (e) => {
        let event = e.originalEvent;
        if (event?._reactName === "onChange")
            return fileValidation(event.target.files)
    };

    const onFileRemove = (file, callback, label) => {
        form.setFieldValue(field, null);
        form.setFieldValue('accept', false)
        callback();
    };

    const onFileUploadSelect = (event) => {
        const fileType = event.files[0].type
        auditEvents(trackingId, SELECT_FILE, field, fileType, SUCCESS_FLAG, '');
        form.setFieldValue(field, event.files[0])
        form.setFieldValue('accept', false)
        form.setFieldTouched(field, false)
        form.setFieldError(field, null)
    };
    const onBeforeDrag = (e) => {
        let event = e.dataTransfer;
        return fileValidation(event?.files)
    }

    const onFileClear = () => {
        form.setFieldValue('accept', false)
    };

    const formatFileSize = (bytes, base = 1000) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(base)));
        const size = bytes / Math.pow(base, i)
        return size.toFixed(2) + sizes[i]
    }


    const filePreviewTemplate = (file, fileProps) => {
        return (
            <div className="flex-file align-items-center flex-wrap space-between">
                <div className="flex-file align-items-center" style={{ width: '60%' }}>
                    <span className="flex-file flex-column text-left font-size-12">
                        {file.name}
                    </span>
                </div>
                <div>
                    <Tag value={fileProps?.formatSize} severity="primary" className="px-3 py-2" />
                    {!currentState && <button className="pi pi-times toggle-btn ml-12"
                        style={{ color: 'red' }}
                        onClick={(e) => {
                            e.preventDefault();
                            onFileRemove(file, fileProps?.onRemove, fileProps?.props?.chooseLabel)
                        }}
                        title='Remove file'
                        data-testid='remove-file'
                    ></button>}
                </div>
            </div>
        );
    };

    const dragDropTemplate = (options) => {
        if (form.values[field] && currentState) {
            return (
                <div className="flex-file align-items-center flex-wrap space-between">
                    <div className="flex-file align-items-center" style={{ width: '60%' }}>
                        <span title='File size' className="flex-file flex-column text-left font-size-12">
                            {form.values[field].name}
                        </span>
                    </div>
                    <div>
                        <Tag value={formatFileSize(form.values[field]?.size)} severity="primary" className="px-3 py-2" />
                        {currentState.status === UploadStatusEnum.FAILED && <button className="pi pi-times toggle-btn ml-12"
                            style={{ color: 'red' }}
                            onClick={(e) => {
                                e.preventDefault();
                                onFileRemove(form.values[field], options?.onClear)
                            }}
                            title='Remove file'
                            data-testid='remove-file'
                        ></button>}
                    </div>
                </div>

            )
        }
        return (
            <div className="flex align-items-center flex-column">
                <span style={{ color: 'var(--text-color-secondary)' }} className="my-5 font-size-12">
                    {
                        componentConfig[field].dragDropText
                    }
                </span>
            </div>
        );
    };

    const getProgressTemplate = (status) => {
        if([UploadStatusEnum.INPROGRESS, UploadStatusEnum.PAUSING].includes(status))
            return (
                <div title={status === UploadStatusEnum.PAUSING ? "please wait, pausing inprogress" : "Pause Upload"}>
                    <Button 
                        icon='pi pi-pause'
                        disabled={status === UploadStatusEnum.PAUSING}
                        className='icon-btn-upload ml-3' 
                        aria-label='Pause-Btn'
                        onClick={() => evaporate.pause()}
                    />
                </div>
            )
        else if(status === UploadStatusEnum.PAUSED)
            return (
                <Button 
                    title="Resume Upload"
                    icon='pi pi-play'
                    className='icon-btn-upload ml-3' 
                    aria-label='Pause-Btn'
                    onClick={() => evaporate.resume()}
                />
            )
        else if(status === UploadStatusEnum.QUEUED){
            return <i title='Pending upload' className='pi pi-clock'></i>
        }            
        else if(status === UploadStatusEnum.COMPLETED){
            return <i title='Uploaded Successfully!' className='pi pi-check'></i>
        }            
        else 
            return (<></>)
    }

    const progressTemplate = () => {
        if (trackingId) {
            if (currentState) {
                const { progress, status } = currentState.fileInfo.find(({ reportType }) => reportType === field);
                    return (
                        <div className="d-flex align-items-center gap-3 ml-auto">
                            {
                                progress && 
                                <ProgressBar
                                    value={progress} 
                                    showValue={false} 
                                    style={{ width: '10rem', height: '12px', margin:'10px' }}
                                />
                            }
                            {getProgressTemplate(status)}
                        </div>
                    )
            }
        }
        // return (
        //     <progress value={0} max="100"></progress>
        // )
    }

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        const choooseButtonWithTooltip = React.cloneElement(chooseButton, {
            title: componentConfig[field].tooltipText
        })

        return (
            <div className={className} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {choooseButtonWithTooltip}
                </div>
                {progressTemplate()}
            </div>
        );
    }

    return (
            <FileUpload
                id={field}
                name={field} accept={componentConfig[field].acceptedFiles}
                chooseLabel={componentConfig[field].labelText}
                onBeforeSelect={(e) => onBeforeSelect(e)}
                onBeforeDrop={(e) => onBeforeDrag(e)}
                onSelect={(e) => onFileUploadSelect(e)}
                onError={onFileClear} onClear={onFileClear}
                emptyTemplate={dragDropTemplate}
                headerTemplate={headerTemplate}
                itemTemplate={filePreviewTemplate}
                disabled={disable}
                data-testid={`upload-${field}`}
                
            // disabled={uploading}
            />
    );
};

FileUploadComponent.propTypes = {
    form: PropTypes.any.isRequired,
    field : PropTypes.string.isRequired,
    trackingId : PropTypes.number.isRequired,
    disable : PropTypes.bool
};

export default FileUploadComponent;
