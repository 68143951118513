import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import Footer from './Footer';
import Header from './Header';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { getCurrentAuthenticatedUser, intAmplify } from '../aws-amplify-init';
import CustomAuthFlow from './custom_authentication/CustomAuthFlow';
import {  API_ENDPOINTS } from '../utils/UrlUtils';
import moment from 'moment'
import { invokePostApi } from '../utils/api';

function SignInView() {
    const [authorizationFlow, setAuthorizationFlow] = useState(null);
    const [user, setUser] = useState(null);
    const [amplifyStatus, setAmplifyStatus] = useState('UNKNOWN');
    const isAmplifyInitialized = useRef(false);  
    
    const authenticateUser = () =>{
        getCurrentAuthenticatedUser().then(userData => {setUser(userData)
            invokePostApi(API_ENDPOINTS.LOGIN, {
                "login_date": moment().utc().format('MM/DD/YYYY'),
                "user_id": userData.username
            })
        });
    }
    useEffect(() => {
        if (!isAmplifyInitialized.current) {
            isAmplifyInitialized.current = true;
            intAmplify().then((response) => {
                let authflow = response?.apiConfig?.authenticationFlowType;
                setAuthorizationFlow(authflow);
                try {
                    Hub.listen('auth', ({ payload: { event, data } }) => {
                        console.log('Unknown error', event + "  " + data);
                        switch (event) {

                            case 'signIn':
                            case 'cognitoHostedUI':  
                            authenticateUser();
                                break;
                            case 'signOut':
                                setUser(null);
                                break;
                            case 'signIn_failure':
                            case 'cognitoHostedUI_failure':
                                console.log('Sign in failure', data);                                
                                break;
                            default:
                                
                        }
                    });
                    setAmplifyStatus(response.status)
                } catch (error) {
                    setAmplifyStatus(response.status)
                }
            }).catch((err) => {
                setAmplifyStatus('ERROR')
            });
        }
    }, []);

    useEffect(() => {
        if (!user && amplifyStatus === 'INITIALIZED') {
            if(authorizationFlow === 'CUSTOM_AUTH'){
                //Do MFA Authentication                               
            }else{
                Auth.federatedSignIn()
            }            
        }
    }, [user, amplifyStatus, authorizationFlow]);
    const navigate = useNavigate();

    const federatedSigninView = () => {
         return <div className='buttons-holder'>
            You are being redirected to sign in.<br />
            <p style={{ marginTop: "6px" }}>
                If you are not redirected,
                <a href="/" className="" onClick={() => Auth.federatedSignIn()}> click here </a>to sign in
                </p>
        </div>
    }

    const canShowHeaderFooter = () => {
        return amplifyStatus !== 'INITIALIZED' || (!authorizationFlow || authorizationFlow !== 'CUSTOM_AUTH');
    }

    return (
        user ? (
            navigate('/')
        ) : (
            <div>
                { canShowHeaderFooter() && <Header showLogout={false}> </Header> }
                
                {amplifyStatus === 'INITIALIZED' && authorizationFlow === 'CUSTOM_AUTH' ?                        
                        <CustomAuthFlow setUser={setUser}/>
                        : amplifyStatus === 'INITIALIZED' && federatedSigninView() 
                }

                {(amplifyStatus === 'UNKNOWN') &&
                    <div className='buttons-holder' data-testid='loading-covera'>
                        Loading the Covera Provider Portal. Please wait...<br />
                    </div>
                }
                {(amplifyStatus === 'ERROR') &&
                    <div className='buttons-holder'>
                        Error while initalizing the authetication api.  <a href="/" className="" onClick={() => navigate('/')}> click here </a> to try again.
                    </div>
                }
                {canShowHeaderFooter() && <Footer />}
            </div>
        )
    );
}

export default SignInView;