import React, {
  createContext, useReducer, useContext, useCallback, useState, useEffect
} from 'react';
import { getEvaporateConfig, UploadStatusEnum } from './UploadConfig';
import Evaporate from 'evaporate';
import { STUDY_UPLOAD_REPORT_TYPE } from '../../../utils/Utils';
import { ReferralService } from '../ReferralService';
import PropTypes from 'prop-types';

// Actions
const ADD_FILE = 'ADD_FILE';
const REMOVE_FILE = 'REMOVE_FILE';
const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

// Initial state
const initialContextState = {
  queue: [],
};

// Reducer
function uploadReducer(state, action) {
  switch (action.type) {
    case ADD_FILE:
      let existingQueue = state.queue.filter(payload => payload.id !== action.payload.id)
      return { ...state, queue: [...existingQueue, action.payload] };
    case REMOVE_FILE:
      return { ...state, queue: state.queue.filter(payload => payload.id !== action.payload) };
    case UPDATE_PROGRESS:
      return {
        ...state,
        queue: state.queue.map(payload =>
          payload.id === action.payload.id ? { ...payload, ...action.payload } : payload
        ),
      };
    default:
      return state;
  }
}

// Context
const UploadQueueContext = createContext();

// Provider component
export function UploadQueueContextProvider({ children, apiConfig }) {
  const [uploadContextState, dispatch] = useReducer(uploadReducer, initialContextState);
  const [isUploading, setIsUploading] = useState(false);
  const [evaporate, setEvaporate] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const SELECT_FILE = 'SELECT_FILE'
  const UPLOAD_FILE = 'UPLOAD_FILE'
  const SUCCESS_FLAG = "SUCCESS"  

  const addToQueue = useCallback(payload => dispatch({ type: ADD_FILE, payload: payload }), []);

  const removeFile = useCallback(fileId => dispatch({ type: REMOVE_FILE, payload: fileId }), []);

  const getCurrentQueue = useCallback((trackingId) => {
    return uploadContextState?.queue?.find(({ id }) => id === trackingId)
  }, []);
  const uploadComplete = useCallback((payload) => {
    dispatch({ type: UPDATE_PROGRESS, payload: payload })
    setIsUploading(false)
  }, []);

  const updateProgress = useCallback((payload) => {
    setIsUploading(true)
    dispatch({ type: UPDATE_PROGRESS, payload: payload })
  }, []);

  const updateFailed = useCallback((payload) => {
    dispatch({ type: UPDATE_PROGRESS, payload: payload })
    setIsUploading(false)
  }, []);

  // Initialize Evaporate
  const initializeEvaporate = useCallback(async () => {
    const s3Config = await getEvaporateConfig(apiConfig)
    Evaporate.create(s3Config)
      .then(instance => {
        setEvaporate(instance);
      })
      .catch(error => console.error('Evaporate initialization error:', error));
  }, []);

  // -------------------------------------------------------------


  const auditEvents = useCallback((id, action_sub_type, reportType, fileType, status, reason) => {
    let fileExt = ""
    if (reportType === "dicom") {
      reportType = STUDY_UPLOAD_REPORT_TYPE.DICOM;
      fileExt = STUDY_UPLOAD_REPORT_TYPE.ZIP_EXTENSION
    } else if (reportType === "report") {
      reportType = STUDY_UPLOAD_REPORT_TYPE.REPORT;
      fileExt = STUDY_UPLOAD_REPORT_TYPE.PDF_EXTENSION;
    }
    if (action_sub_type === SELECT_FILE && status === UploadStatusEnum.FAILED)
      fileExt = fileType

    let requestData = {
      'exam_id': id,
      'action_sub_type': action_sub_type,
      'upload_event': {
        'componentType': reportType,
        'fileType': fileExt,
        'status': status
      }
    }
    if (reason) {
      requestData['upload_event']['reason'] = reason
    }
    ReferralService.auditUploadEvent(requestData);
  }, [])

  const updateUploadStatus = (exam_id, report_type, status, failure_reason = null, progress_value = null) => {
    let requestData = {
      'exam_id': exam_id,
      'report_type': report_type,
      'status': status,
      'failure_reason': failure_reason,
      'progress_value': progress_value
    }
    ReferralService.updateStudyUploadStatus(requestData);
  }

  const updateAuthStatus = (id) => {
    const data = {
      "exam_id_list": [
        id
      ],
      "exam_status": 'COLL_COMPLETE'
    }
    ReferralService.submitAuthInfo(data)
  }

  const getProgressValue = (progressValue) => (progressValue * 100).toFixed(0);

  const onUploadStartCallback = (id, fileInfo, evaporatingFile) => {
    auditEvents(id, UPLOAD_FILE, evaporatingFile.reportType, evaporatingFile?.file?.type, UploadStatusEnum.STARTED, '');
    evaporatingFile.status = UploadStatusEnum.STARTED
    updateProgress({
      id,
      fileInfo: fileInfo.map((f) =>
        f.reportType === evaporatingFile.reportType ? { ...f, ...evaporatingFile } : f
      ),
      status: UploadStatusEnum.STARTED,
      isUploading: true
    });
    updateUploadStatus(id, evaporatingFile.reportType, UploadStatusEnum.STARTED)
  }
  const onUploadProgressCallback = (progressValue, id, fileInfo, evaporatingFile) => {
    if (progressValue < 1 && evaporatingFile.status !== UploadStatusEnum.PAUSING) {
      evaporatingFile.status = UploadStatusEnum.INPROGRESS
      evaporatingFile.isUploading = true
      let progressVal = getProgressValue(progressValue)
      evaporatingFile.progress = progressVal
      updateProgress({
        id,
        fileInfo: fileInfo.map((f) =>
          f.reportType === evaporatingFile.reportType ? { ...f, ...evaporatingFile } : f
        ),
        status: UploadStatusEnum.INPROGRESS,
        isUploading: true
      });
      updateUploadStatus(id, evaporatingFile.reportType, UploadStatusEnum.INPROGRESS, null, progressVal)
    }
  }

  const onUploadCompleteCallback = (id, fileInfo, evaporatingFile, file) => {
    console.log('upload completed:', file?.name)
    evaporatingFile.status = UploadStatusEnum.COMPLETED
    evaporatingFile.isUploading = false
    evaporatingFile.progress = 100
    uploadComplete({
      id, fileInfo: fileInfo.map((f) =>
        f.reportType === evaporatingFile.reportType ? { ...f, ...evaporatingFile } : f
      ),
      status: UploadStatusEnum.COMPLETED,
      isUploading: true
    })
    updateUploadStatus(id, evaporatingFile.reportType, UploadStatusEnum.COMPLETED, null, 100)
    
    auditEvents(id, UPLOAD_FILE, evaporatingFile.reportType, evaporatingFile?.file?.type, SUCCESS_FLAG, '');
    if (fileInfo?.every(res => res?.status == UploadStatusEnum.COMPLETED)){
      updateAuthStatus(id);
      setRefreshTable(true)
    }     
  }

  const onErrorCallback = (id, fileInfo, evaporatingFile, error) => {
    console.log('on Upload error callback:')
    let auditMessageOnError = `${error}. Aborted the upload at ${evaporatingFile?.progress}% progress.`
    auditEvents(id, UPLOAD_FILE, evaporatingFile.reportType, evaporatingFile?.file?.type, UploadStatusEnum.FAILED, auditMessageOnError);
    const failedInfo = {
      status: UploadStatusEnum.FAILED, isUploading: false
    }
    updateFailed({
      id,
      fileInfo: fileInfo.map((f) => (
        { ...f, ...failedInfo } 
      )),
      status: UploadStatusEnum.FAILED,
      isUploading: false
    });
    updateUploadStatus(id, evaporatingFile.reportType, UploadStatusEnum.FAILED, error)
  }

  const onPauseCallback = (id, fileInfo, evaporatingFile, file) => {
    auditEvents(id, UPLOAD_FILE, evaporatingFile.reportType, evaporatingFile?.file?.type, UploadStatusEnum.PAUSED, '');
    console.log('paused:', file.name)
    evaporatingFile.status = UploadStatusEnum.PAUSED
    evaporatingFile.isUploading = true
    updateProgress({
      id, fileInfo: fileInfo.map((f) =>
        f.reportType === evaporatingFile.reportType ? { ...f, ...evaporatingFile } : f
      )
    })
  }

  const onPausingCallback = (id, fileInfo, evaporatingFile, file) => {
    console.log('pausing:', file?.name)
    evaporatingFile.status = UploadStatusEnum.PAUSING
    evaporatingFile.isUploading = true
    updateProgress({
      id, fileInfo: fileInfo.map((f) =>
        f.reportType === evaporatingFile.reportType ? { ...f, ...evaporatingFile } : f
      )
    })
  }

  const onCancelCallback = (id, fileInfo, evaporatingFile, file) => {
    let auditMessageOnCancel = `User aborted the upload at ${evaporatingFile?.progress}% progress.`
    auditEvents(id, UPLOAD_FILE, evaporatingFile.reportType, evaporatingFile?.file?.type, UploadStatusEnum.FAILED, auditMessageOnCancel);
    console.log('upload cancelled', file.name)
    const failedInfo = {
      status: UploadStatusEnum.FAILED, isUploading: false, progress : null
    }
    updateUploadStatus(id, evaporatingFile.reportType, UploadStatusEnum.FAILED, `Cancelled by the user at ${evaporatingFile?.progress}% progress.`)
    updateFailed({
      id,
      fileInfo: fileInfo.map((f) => (
        {...f, ...failedInfo}
      )),
      status: UploadStatusEnum.FAILED,
      isUploading: false
    });
  }
  
  // -------------------------------------------------------------
  const uploadFile = useCallback(async (id, fileInfo, evaporatingFile) => {
    if (!evaporate) return Promise.reject(new Error('Evaporate instance not initialized'));
    if (!evaporatingFile?.file) return new Promise((resolve, reject)=>{
      onUploadCompleteCallback(id, fileInfo, evaporatingFile)
      resolve();
    })
    return new Promise((resolve, reject) => {
      if (!fileInfo || evaporatingFile.status !== UploadStatusEnum.QUEUED) return;
      console.log('upload file started', evaporatingFile?.file?.name)

      const file = evaporatingFile.file

      const config = {
        name: evaporatingFile.s3_dir_name + file.name,
        file: file,
        started: (fid) => {
          onUploadStartCallback(id, fileInfo, evaporatingFile)
        },
        progress: (progressValue) => {
          onUploadProgressCallback(progressValue, id, fileInfo, evaporatingFile)
        },
        complete: () => {
          onUploadCompleteCallback(id, fileInfo, evaporatingFile, file)
          resolve(file.name);
        },
        paused: () => {
          onPauseCallback(id, fileInfo, evaporatingFile, file)
        },
        pausing: () => {
          onPausingCallback(id, fileInfo, evaporatingFile, file)
        },
        cancelled: () => {
          onCancelCallback(id, fileInfo, evaporatingFile, file)
        },
        error: (error) => {
          onErrorCallback(id, fileInfo, evaporatingFile, error)
        }
      }

      evaporate.add(config).then(
        awsObjectKey =>
          console.log('File successfully uploaded to:', awsObjectKey),
        reason =>
          console.log('File did not uploaded sucessfully:', reason)
      )
    });
  }, [evaporate]);

  useEffect(() => {
    initializeEvaporate();
  }, [initializeEvaporate]);

  useEffect(() => {
    const processQueue = async () => {
      for await (const { fileInfo, id, status } of uploadContextState.queue) {
        if (status === UploadStatusEnum.QUEUED) {
          // console.log('queued items:', uploadContextState.queue)
          setIsUploading(true)
          await uploadFile(id, fileInfo, fileInfo[0]).then(async () => {
            if (fileInfo.length > 1 && fileInfo[1].status === UploadStatusEnum.QUEUED)
              await uploadFile(id, fileInfo, fileInfo[1])
          });
          uploadComplete({
            id, status: UploadStatusEnum.COMPLETED, isUploading: false, isCompleted: true
          })
          setIsUploading(false)
        }
      };
    }

    if (!isUploading)
      processQueue();
  }, [uploadContextState.queue]);


  return (
    <UploadQueueContext.Provider value={{
      uploadContextState, isUploading, addToQueue, removeFile,
      evaporate, updateFailed, getCurrentQueue, apiConfig, auditEvents, 
      refreshTable, setRefreshTable
    }}>
      {children}
    </UploadQueueContext.Provider>
  );
}

UploadQueueContextProvider.propTypes = {
  children: PropTypes.any,
  apiConfig: PropTypes.object
 };

// Custom hook to use the UploadContext
export function useUploadQueueContext() {
  return useContext(UploadQueueContext);
}