import React, { useState } from "react";
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import PinInputView from './PinInputView';
import { Auth } from 'aws-amplify';
import { 
    TEXT, UI_COMPONENT, 
    clearAlert, setAlertMessage
} from './CustomAuthUtils'


/*
MFAChallengeUI - It is a component
It is rendered after successfull submission of Auth Challenge.
It contains the following
    - Form with Verification Code Input field
    - Form validations
    - Resend Verification Code
    - Request Help
Makes an API call using AWS-AMPLIFY to do Verify Auth Challenge(MFA).
After succesfull MFA, UserSession to be assigned and allowing them to use our applications.
 */
function MFAChallengeUI(props) {  
    const [mfaValid, setMfaValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const maxLength = 6; //For 6 Digit pin
    const [mfaInput, setMFAInput] =  useState(Array(maxLength).fill(''));

    //Declaring form components.
    const mfaChallengeForm = {
        verification_code: ""
    }

    //Validations for UI.
    const verifyChallengeFormValidation = values => {
        const errors = {};
        if (!values.verification_code || values.verification_code.trim() === '') {
            errors.verification_code = TEXT.VERIFICATION_CODE_REQUIRED;
            setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.VERIFICATION_CODE_REQUIRED);
            setMfaValid(false);
        }
        else if (formik.values.verification_code.length !== 6) {
            errors.verification_code = TEXT.VERIFICATION_CODE_NOT_VALID_LENGTH;
            setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.VERIFICATION_CODE_NOT_VALID_LENGTH);
            setMfaValid(false);
        }
        return errors;
    }

    //Defining form.
    const formik = useFormik({
        initialValues: mfaChallengeForm,
        validate: verifyChallengeFormValidation,
        onSubmit: async (data) => {
            clearAlert(props);
            data && verifyAuthChallenge(data.verification_code);
        }
    });

    /*
    VerifyAuthChallenge - API call to cognito
    It submits the Verification Code to cognito.
    Returns the response with User Data only if verification code is valid.
    if not, it returns user without the  signInUserSession. This case we should consider it is invalid MFA.  
    We should check userSession before submitting sendCustomChallengeAnswer() request
        - if userSession is not found, we should render to AuthChallenge Component for User Authentication.
        - If userSession is available, we should allow user to access our appilcation, it redirects to Referral list screen.
     */
    const verifyAuthChallenge = async (verification_code) => {
        setLoading(true);
        if (props.userSession) {
            await Auth.sendCustomChallengeAnswer(props.userSession, verification_code).then(chResponse => {
                if (chResponse?.signInUserSession)
                    props.setUser(chResponse);
                else
                    setAlertMessage(props, TEXT.ERROR_CLASS_NAME, TEXT.INVALID_VERIFICATION_CODE);
            }).catch(err => {
                setAlertMessage(props, TEXT.ERROR_CLASS_NAME, err.message);
            });
        } else {
            props.setUIComponent(UI_COMPONENT.AUTH_CHALLENGE)
        }
        setLoading(false);
    }

    //To control the PIN inputs.
    const handleMFAEntered = (enteredOTP) => {
        formik.values.verification_code = enteredOTP.toString();
        setMfaValid(true)
        clearAlert(props);
    }

    /*
    handelResendClick - Resend the MFA code to user.
    Using the AuthInfo props, makes the api call Auth.signIn() to generate the MFA again.
    - After successfull submit, assign the userSession to global state.
    - If it is failure/AuthInfo is not found, we should render the AuthChallenge Component.
     */
    const handelResendClick = async (event) => {
        clearAlert(props);
        setLoading(true);
        formik.values.verification_code = '';
        setMFAInput(Array(maxLength).fill(''));
        if (props?.authInfo) {
            let email = props.authInfo?.email;
            let password = props.authInfo?.password;
            if(email && password){
                await Auth.signIn(email, password).then((user) => {
                    props.setUserSession(user);
                    setAlertMessage(props, TEXT.SUCCESS_CLASS_NAME, TEXT.MFA_RESEND_SUCCESS);
                }).catch(err => {
                    setAlertMessage(props, TEXT.ERROR_CLASS_NAME, err.message);
                });
            }else{
                props.setUIComponent(UI_COMPONENT.AUTH_CHALLENGE)
            }            
        } else {
            //display error message for no user session.
            props.setUIComponent(UI_COMPONENT.AUTH_CHALLENGE)
        }
        setLoading(false);
    }

    //Rendering the form VerifyAuthChallenge(MFA).
    return (
        <div className="mfa-view-parent">
            <form className="flex flex-column gap-2" onSubmit={formik.handleSubmit} data-testid="mfa-form">
                <div className="mfa-view-parent">
                    <p className='landing-subtext w-80'>{TEXT.ENTER_MFA_CODE_TEXT}</p>
                    <PinInputView
                        handleMFAEntered={handleMFAEntered} MFAvalid={mfaValid} 
                        setMFAInput = {setMFAInput} mfaInput = {mfaInput} maxLength={maxLength}
                    />
                    <div className='help-container'>
                        <a className='help-info' onClick={handelResendClick} data-testid="resend-link">{TEXT.MFA_RESEND_CODE}</a>
                        <p className='help-info'>|</p>
                        <a className='help-info' href='/help'>{TEXT.MFA_REQUEST_HELP}</a>
                    </div>
                </div>
                <Button data-testid="submit-btn" type="submit" label={TEXT.SUBMIT} className="signon-btn lgn-pg-btn" loading={loading} />
            </form>
        </div>
    );
}

export default MFAChallengeUI;