export const API_PREFIX = {
    VERSION : "/api/v1",
    PUBLIC  : "/public"
};

export const API_ENDPOINTS = {
    GET_USERS: "/user/list",
    GET_FACILITIES: "/facility_names",
    GET_MODALITY: "/modality",
    SSO: "/sso",
    RESET_PWD_INVITATION : "/user/reset_password_invitation", 
    GET_MASKED_MAIL : "/user/get_masked_email",
    CHANGE_PASSWORD : "/user/change_password",
    LOGOUT : '/logout',
    LOGIN  : '/login',
    SESSION_TIMEOUT : '/sessiontimeout',
    REFERRALS : '/referrals',
    UPDATE_AUTHORIZATION : '/authinfo/update',
    AUDIT : '/authinfo/audit',
    ADD_USER : '/user/add',
    UPDATE_USER : '/user/update',
    RESEND_INVITATION: "/user/resend_invitation",
    RESET_PASSWORD : "/user/reset_password",
    USER_AGREEMENT :"/user/agreement",
    USER_AGREEMENT_ACCEPT : "/user/agreement/accept",
    CSRF : "/public/api/v1/csrf",
    CONFIRM_RESET_PWD_EMAIL : '/user/confirm_reset_password',
    GET_EXAM_DETAILS: "/exams",
    GET_S3_CONFIG: "/get_s3_config",
    SIGN_AUTH_V4 : '/sign_auth_v4',
    STUDY_UPLOAD_AUDIT : '/upload/audit',
    STUDY_UPLOAD_STATUS_UPDATE : '/study-upload/status',
    GET_STUDY_UPLOAD_DETAILS: '/study-upload'
};

export const public_endpoints = [
    API_ENDPOINTS.SSO, API_ENDPOINTS.RESET_PWD_INVITATION, 
    API_ENDPOINTS.GET_MASKED_MAIL, API_ENDPOINTS.CHANGE_PASSWORD    
];