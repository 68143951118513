import Header from '../Header';
import Footer from '../Footer';
import React from 'react';
import { Button } from 'primereact/button';
import './index.css'
import { useNavigate } from 'react-router-dom';
function HelpView(props) {
    const navigate = useNavigate()

    return (
        <div>
            {!props?.user && <Header showLogout={false} />}
            <div id="content">
                <h1 className="h1-b">Help</h1>
                <br/>
                <span className="contact-description">To contact help, please email <a className="contact-email" href="mailto:support@coverahealth.com" >support@coverahealth.com</a> </span>
                {!props.user && <Button className='p-button-custom' onClick={() => navigate("/login")} data-testid='submit-btn'>Go back to login page</Button>}
            </div>
            <Footer user ={props?.user}  setSelectedTab={props?.setSelectedTab}/>
        </div>
    )
}
export default HelpView;