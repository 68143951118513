import '../../App.css';
import './manage_users.css'
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { ManageUsersService } from './service/ManageUserService';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';
import { APP_TITLE } from '../../utils/Utils';
const Text = {
    LOADING: "Validating your password reset request. Please wait...",
    PASSWORD_RESET_INPROGRESS: "Resetting your password. Please wait...",
    PASSWORD_RESET_SUCCESS: "Resetting your password. Please wait...",
    PASSWORD_REQUEST_INVALID: "Your password reset link has expired. Please submit a new password reset request.",
    PASSWORD_REQUIRED: 'Password is required',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required',
    PASSWORD_DO_NOT_MATCH: 'New Password and Confirm New Password must match.',
    PASSWORD_RESET_REQUEST_FAILED: 'Password reset failed. Please try again and re-confirm that the password requirements have been met.',
    PASSWORD_RESET_ERROR: 'Password reset failed. Please try again later.',
    PASSWORD_RESET_COMPLETE: 'Your password has been successfully reset!',
    PREVIOUS_PASSWORD_WARNING : 'This password has alredy been used, try different password.',
    PASSWORD_LENGTH_VALIDATION_MESSAGE : "Password must have at least 10 characters"
}

function RedirectToSignIn(props) {
    return <div>Please <a href="/" className="" onClick={props.navigateToLogin}>click here</a> to log in with your new password.</div>
}

function RedirectToSignInError(props) {
    const navigateToLogin = props?.navigateToLogin;
    return <div><a href="/" onClick={navigateToLogin}>Click here</a> to return to the login page.</div>
}
//Component
function PasswordResetLoadingComponent(props) {
    return <div className='custom-auth-container'>
        <div>
            <div className="card flex justify-content-left form-div">
                <div style={{ float: "left", paddingRight: "10px" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></div>
                <div>{Text.LOADING}<br /></div>
            </div>
        </div>
    </div>
}
function PasswordResetInprogressComponent(props) {
    return <div className='custom-auth-container'>
        <div>
            <div className="card flex justify-content-left form-div">
                <div style={{ float: "left", paddingRight: "10px" }}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                </div>
                <div>{Text.PASSWORD_RESET_INPROGRESS}</div>
            </div>
        </div>
    </div>
}
function PasswordResetSuccess(props) {
    return <div className='password-reset-success'>
        <p>{Text.PASSWORD_RESET_COMPLETE}</p>
        <RedirectToSignIn></RedirectToSignIn>
    </div>

}

function PasswordResetError(props) {
    return <div className='password-reset-error'>
        <p>
            <p className='p-reset-error'>{props.errorMessage}</p>
            <RedirectToSignInError></RedirectToSignInError>
        </p>
    </div>

}

function ResetPasswordUI(props) {

    const ScreenState = {
        LOADING: "LOADING",
        LOADED: "LOADED",
        COMPLETED: "COMPLETED",
        ERROR: "ERROR",
        UNRECOVERABLE_ERROR: "UNRECOVERABLE_ERROR",
        PASSWORD_RESET_INPROGRESS: "PASSWORD_RESET_INPROGRESS"
    }
    //Password reset status has 3 state.
    //1. User successfully completed the password reset
    //2. User tried a password reset with an invalidcode or password (User can fix it and try it again)
    //3. An unrecoverable error occured. 
    const [passwordResetFlowStatus, setPasswordResetFlowStatus] = useState({
        status: ScreenState.LOADING,
        message: "",
    });
    const [searchParams,] = useSearchParams()    

    const fetchMaskedEmail = useCallback(async () => {
        try {
            
            let code = searchParams.get('code');
            if (code) {
                const response = await ManageUsersService.get_masked_email(searchParams.get('code'));
                if (response.status === 200) {
                    let emailResponse = await response.data;
                    if (emailResponse.email) {
                        setPasswordResetFlowStatus({
                            status: ScreenState.LOADED,
                        })                        
                    } else {
                        setPasswordResetFlowStatus({
                            status: ScreenState.UNRECOVERABLE_ERROR,
                            message: Text.PASSWORD_REQUEST_INVALID
                        })
                    }
                } else {
                    setPasswordResetFlowStatus({
                        status: ScreenState.UNRECOVERABLE_ERROR,
                        message: Text.PASSWORD_REQUEST_INVALID
                    })
                }
            } else {
                props.navigateToLogin()
            }
        } catch (error) {
            setPasswordResetFlowStatus({
                status: ScreenState.UNRECOVERABLE_ERROR,
                message: Text.PASSWORD_REQUEST_INVALID
            })
        }
    }, [ScreenState.LOADED, ScreenState.UNRECOVERABLE_ERROR, props, searchParams])

    useEffect(() => {
        setPasswordResetFlowStatus({
            status: ScreenState.LOADING,
        })
        fetchMaskedEmail()
    }, [ScreenState.LOADING, fetchMaskedEmail])

    const passwordForm = {
        code: "",
        password: "",
        confirm_password: "",
        password_reset_code: ""
    }
    const formValidation = values => {
        const errors = {};
        if (!values.password || values.password.trim() === '') errors.password = Text.PASSWORD_REQUIRED;        
        else if(values.password.length < 10) errors.password = Text.PASSWORD_LENGTH_VALIDATION_MESSAGE;
        if (!values.confirm_password) {
            errors.confirm_password = Text.CONFIRM_PASSWORD_REQUIRED;
        }
        else if (values.confirm_password !== values.password) {
            errors.confirm_password = Text.PASSWORD_DO_NOT_MATCH;
        }
        return errors;
    };

    const submitData = async (data) => {
        setPasswordResetFlowStatus({
            status: ScreenState.PASSWORD_RESET_INPROGRESS,
        })
        ManageUsersService.confirmForgotPassword(data).then(async (response) => {
            if (response.status === 200) {
                setPasswordResetFlowStatus({
                    message: "",
                    status: 'COMPLETED',
                })
            } else if (response.status === 500) {
                setPasswordResetFlowStatus({
                    status: ScreenState.ERROR,
                    message: Text.PASSWORD_RESET_REQUEST_FAILED
                })
                formik.resetForm();
            } else if (response.status === 400) {
                setPasswordResetFlowStatus({
                    status: ScreenState.ERROR,
                    message: response.data.message
                })
                formik.resetForm();
            } else {                
                setPasswordResetFlowStatus({
                    status: ScreenState.UNRECOVERABLE_ERROR,
                    message: Text.PASSWORD_RESET_ERROR
                })
                formik.resetForm();
            }
        }).catch((error) => {
            setPasswordResetFlowStatus({
                status: ScreenState.UNRECOVERABLE_ERROR,
                message: Text.PASSWORD_RESET_ERROR
            })
        })
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: passwordForm,
        validate: formValidation,
        onSubmit: (data) => {
            const trimedData = {};
            // Trim the empty string
            Object.entries(data).forEach(item => {
                trimedData[item[0]] = item[1];
            })
            trimedData['password_reset_code'] = searchParams.get('code')
            trimedData['code'] = searchParams.get('key')            
            trimedData && submitData(trimedData);
        }
    });

    const footer = (
        <>
            <p className="mt-2"><b>Password Requirements</b></p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>Password must contain a lower case letter</li>
                <li>Password must contain an upper case letter</li>
                <li>Password must contain a number</li>
                <li><b>Password must contain a special character</b></li>
                <li><b>Password must have at least 10 characters</b></li>
                <li>Password must not contain a leading or trailing space</li>
            </ul>
        </>
    );

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <p className="p-error">{formik.errors[name]}</p> : '';
    };
    const onClick = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    }

    if (passwordResetFlowStatus.status === ScreenState.COMPLETED) {
        return <PasswordResetSuccess navigateToLogin={props.navigateToLogin}></PasswordResetSuccess>
    }
    if (passwordResetFlowStatus.status === ScreenState.UNRECOVERABLE_ERROR) {
        return <PasswordResetError navigateToLogin={props.navigateToLogin} errorMessage={passwordResetFlowStatus.message}></PasswordResetError>
    }
    if (passwordResetFlowStatus.status === ScreenState.LOADING) {
        return <PasswordResetLoadingComponent></PasswordResetLoadingComponent>
    } 
    if (passwordResetFlowStatus.status === ScreenState.PASSWORD_RESET_INPROGRESS) {
        return <PasswordResetInprogressComponent></PasswordResetInprogressComponent>
    }
    const strongRegex='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})';
    return <div>

        <div className='custom-auth-container'>
            <div>
                <div className="card flex justify-content-left form-div">
                    {passwordResetFlowStatus.status === ScreenState.ERROR && <div className='p-reset-error'><p>{passwordResetFlowStatus.message}</p></div>}
                    <form className="flex flex-column gap-2">
                        <div className='pasword-reset-form-field password-field'>
                            <label htmlFor="input_value">New Password</label>
                            <Password
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={(e) => {
                                    formik.setFieldValue('password', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('password') }, 'p-password-cust')}
                                footer={footer}
                                strongRegex={strongRegex}
                                data-testid="new-password"
                            />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className='pasword-reset-form-field  password-field'>
                            <label htmlFor="input_value">Confirm New Password</label>
                            <Password
                                id="confirm_password"
                                name="confirm_password"
                                value={formik.values.confirm_password}
                                onChange={(e) => {
                                    formik.setFieldValue('confirm_password', e.target.value);
                                }}
                                feedback={false}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('password') }, 'p-password-cust')}
                                data-testid="confirm-password"
                            />
                            {getFormErrorMessage('confirm_password')}
                        </div>
                        <button type="submit" id="submit-btn" className='signon-btn' onClick={onClick} data-testid="submit-btn">
                            <label> Change Password</label>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
}

function ResetPasswordForm() {
    const [searchParams,] = useSearchParams()
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate('/signin')
    }
    useEffect(() => {
        if (!searchParams.get('code')) {
            navigateToLogin();
        }
    })
    if (!searchParams.get('code')) {
        return;
    }
    return <div className='white-header'>
        <div className='grid-container flush menu-takeover' id='menu-takeover' style={{ zIndex: '1000', }}>
            <div className="grid-container flush home__top">
                <div className="top home-top header-image" style={{ backgroundImage: "url(img/homepage-header-1.webp)" }}>
                    <div className='col c4 menu-logo-container' >
                        <a href='https://www.coverahealth.com/'>
                            <img alt='Covera' data-src='img/logo-light.png' className='logo Ls-is-cached lazyloaded' src='img/logo-light.png' />
                        </a>
                    </div>
                    <div className="col c6--md tl--md">
                        <div className='desktop-text' style={{ color: '#fff' }}>
                            <h1 className='sans--x-large fw--300'>{APP_TITLE}</h1>
                            <ResetPasswordUI navigateToLogin={navigateToLogin}></ResetPasswordUI>

                        </div>
                    </div>
                </div>
                <div className='mobile-text' style={{ color: '#005c62' }}>
                    <div className='col cl2 mobile-header'>
                        <h1 className='sans--x-large fw--300'>{APP_TITLE}</h1>
                        <ResetPasswordUI navigateToLogin={navigateToLogin}></ResetPasswordUI>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

RedirectToSignIn.propTypes = {
    navigateToLogin: PropTypes.func.isRequired
};

PasswordResetError.propTypes = {
    errorMessage: PropTypes.string
};
ResetPasswordUI.propTypes = {
    navigateToLogin: PropTypes.func.isRequired,
};
export default ResetPasswordForm
